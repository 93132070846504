import React from "react";
import {
  DollarValueUp,
  HouseLink,
  HousePin,
  OpenInNewWindow,
  SquareUser,
} from "@craftworkco/nucleo-icons";

import useLocation from "@/hooks/useLocation";
import useProject from "@/hooks/useProject";
import { ProjectType } from "@/types";
import { clsxm } from "@/utils/clsxm";

import { PaintableCountBadge } from "../ui/PaintableCountBadge";
import { ProjectMediaGallerySlider } from "../ui/ProjectMediaGallerySlider";
import { Skeleton } from "../ui/Skeleton";

type ProjectSummaryCardProps = {
  projectId: string;
  orientation?: "horizontal" | "vertical";
};

export const ProjectSummaryCard: React.FC<ProjectSummaryCardProps> = ({
  projectId,
  orientation = "horizontal",
}) => {
  const { data: project } = useProject(projectId);
  const { data: location, isLoading: isLoadingLocation } = useLocation(
    project?.locationPrefixId,
  );

  const paintableCounts = [
    { count: 0, type: ProjectType.Interior },
    { count: 0, type: ProjectType.Cabinets },
    { count: 0, type: ProjectType.Exterior },
    { count: 0, type: ProjectType.Specialty },
  ];

  const hasSomePaintables = paintableCounts.some(
    (paintable) => paintable.count > 0,
  );

  return (
    <div
      className={clsxm(
        "flex rounded-lg border bg-white",
        orientation === "vertical" ? "flex-col" : "items-center",
      )}
    >
      <div
        className={clsxm(
          "w-full overflow-hidden rounded-t-lg",
          orientation === "vertical"
            ? "max-w-full"
            : "min-w-[280px] max-w-[400px]",
        )}
      >
        <ProjectMediaGallerySlider projectId={projectId} />
      </div>
      <div className="space-y-5 p-6">
        <div className="space-y-2">
          <div className="flex items-center gap-2">
            <a
              href={`/projects/${projectId}`}
              className="flex items-center justify-center gap-2 rounded"
              target="_blank"
              rel="noreferrer"
            >
              <h2 className="font-bold leading-none">
                {project?.accountName} - {project?.title}
              </h2>
              <OpenInNewWindow size={20} />
            </a>
          </div>
          {hasSomePaintables && (
            <div className="flex flex-wrap gap-2">
              {paintableCounts
                .filter((paintable) => paintable.count > 0)
                .map((paintable) => (
                  <PaintableCountBadge key={paintable.type} {...paintable} />
                ))}
            </div>
          )}
        </div>
        <ul className="space-y-2">
          <li className="flex items-center gap-2">
            <SquareUser size={20} />
            <a
              href={`/customers/${project?.accountPrefixId}`}
              target="_blank"
              rel="noreferrer"
            >
              {project?.accountName}
            </a>
          </li>
          <li className="flex items-center gap-2">
            <HousePin size={20} />
            {isLoadingLocation ? (
              <Skeleton className="h-6 w-full" />
            ) : (
              <a
                href={`/locations/${location?.prefixId}`}
                target="_blank"
                rel="noreferrer"
              >
                {location?.address.line1}
              </a>
            )}
          </li>
          <li className="flex items-center gap-2">
            <HouseLink size={20} />
            {isLoadingLocation ? (
              <Skeleton className="h-6 w-full" />
            ) : (
              <a
                href={location?.homeListingUrl}
                target="_blank"
                rel="noreferrer"
              >
                Home Listing
              </a>
            )}
          </li>
          <li className="flex items-center gap-2">
            <DollarValueUp size={20} />
            <span>Lead score: {project?.leadScore}</span>
          </li>
        </ul>
      </div>
    </div>
  );
};
