import React, { useEffect, useRef, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Tippy from "@tippyjs/react";
import { format } from "date-fns";
import { AnimatePresence, motion } from "framer-motion";

import { useCalendarCraftworkersQuery } from "@/hooks/useCalendarCraftworkersQuery";
import useRouter from "@/hooks/useRouter";
import type { ProjectTypes } from "@/server_components/emails/EmailBaseLayout";
import type {
  CalendarCraftworker,
  CalendarEvent,
  Shift,
  StaffingCounts,
  Timesheet,
} from "@/types/index";
import { Avatar } from "@/ui/Avatars";
import { BandaidIcon, SprayCanIcon, UserVoiceIcon } from "@/ui/Icons";
import { clsxm } from "@/utils/clsxm";
import {
  getEnglishSpeakers,
  getEnglishSpeakersString,
  getSprayers,
  getSprayersString,
} from "@/utils/craftworkers";

const indicatorClasses =
  "w-5 h-5 bg-gray-100 text-plum rounded-full grid place-items-center leading-none";

type EventHeaderProps = {
  craftworkers: CalendarCraftworker[];
  staffing: StaffingCounts;
  showCount: boolean;
  hideBadges?: boolean;
  countClasses?: string | null;
  reworkReason?: string | null;
  projectType: ProjectTypes | null;
};

const EventHeader: React.FC<EventHeaderProps> = ({
  craftworkers: cws,
  staffing,
  showCount,
  hideBadges,
  countClasses,
  reworkReason,
  projectType,
}) => {
  const craftworkers = cws.filter(Boolean);
  const committed = staffing.committed ?? 0;
  const assigned = staffing.assigned ?? 0;

  const overStaffed = committed < assigned;
  const understaffed = committed > 0 && assigned < committed;

  const englishSpeakers = getEnglishSpeakers(craftworkers);
  const englishSpeakersString = getEnglishSpeakersString(craftworkers);

  const sprayers = getSprayers(craftworkers);
  const sprayersString = getSprayersString(craftworkers);
  const projectTypeAbbreviation = projectType?.substring(0, 3).toUpperCase();

  return (
    <div className="flex w-full items-center justify-between">
      <div className="flex items-center gap-1">
        {!hideBadges && (
          <>
            <span
              className={clsxm(
                indicatorClasses,
                englishSpeakers.length < 1 && "bg-red-100 text-alert",
              )}
              data-controller="tooltip"
              data-tippy-content={
                englishSpeakers.length > 0
                  ? `${englishSpeakersString} speak${englishSpeakers.length === 1 ? "s" : ""} english`
                  : "No english speakers staffed"
              }
            >
              <UserVoiceIcon />
            </span>
            {sprayers.length > 0 && (
              <span
                className={indicatorClasses}
                data-controller="tooltip"
                data-tippy-content={sprayersString + " can spray"}
              >
                <SprayCanIcon />
              </span>
            )}
            {reworkReason && (
              <span
                className={clsxm(indicatorClasses, "bg-yellow-50 text-warning")}
                data-controller="tooltip"
                data-tippy-content={reworkReason}
              >
                <BandaidIcon />
              </span>
            )}
          </>
        )}
      </div>
      <div className="items-cenetr flex gap-1">
        {projectType && (
          <span
            className={clsxm(
              indicatorClasses,
              "ml-auto h-5 w-auto rounded bg-gray-50 px-1 font-bold text-xs text-gray-500",
            )}
          >
            {projectTypeAbbreviation}
          </span>
        )}
        <span
          className={clsxm(
            "ml-auto flex h-5 min-w-5 shrink-0 items-center justify-center rounded bg-green-100 px-1 font-bold text-xs text-green-700",
            understaffed && !showCount && "bg-yellow-50 text-warning",
            overStaffed && !showCount && "bg-red-100 text-alert",
            showCount && "bg-gray-100 text-gray-500",
            countClasses,
          )}
          data-controller="tooltip"
          data-tippy-content={`Staffed ${assigned} of ${committed} committed`}
        >
          {assigned} {!showCount && `of ${committed}`}
        </span>
      </div>
    </div>
  );
};

const ShiftContent: React.FC<{ shift: Shift }> = ({ shift }) => {
  const craftworker = shift.craftworker;

  const timeDisplay = `${format(shift.start, "h:mm a")} - ${format(shift.end, "h:mm a")}`;
  const shiftDisplay = `Scheduled: ${timeDisplay}${!shift.published ? " (Unpublished)" : ""}`;
  return (
    <div
      className={clsxm("relative h-fit w-fit text-xs")}
      key={`shift-${shift.shiftId}`}
    >
      <Tippy
        interactive={true}
        content={
          <div className="min-w-56">
            <div className="block font-bold">{craftworker?.name}</div>
            <section>
              <a
                href={`/shifts/${shift.shiftId}`}
                key={`tippy-${shift.shiftId}`}
                target="_blank"
                rel="noopener noreferrer"
                className="block w-fit text-white underline hover:no-underline"
              >
                {shiftDisplay}
              </a>
            </section>
            <section className="mt-4">
              <div className="font-medium">
                {shift.timesheets.length === 0 && "No"} Timeheets
              </div>
              <div className="flex w-full flex-col gap-2">
                {shift.timesheets.map((timesheet) => (
                  <a
                    href={`/timesheets/${timesheet.timesheetId}`}
                    key={timesheet.timesheetId}
                    target="_blank"
                    className="block w-fit text-white underline hover:no-underline"
                    rel="noreferrer"
                  >
                    {format(timesheet.startedAt, "h:mm a")} -{" "}
                    {format(timesheet.endedAt, "h:mm a")}
                    {timesheet.approved && <span> (approved)</span>}
                  </a>
                ))}
              </div>
            </section>
          </div>
        }
      >
        <div>
          <Avatar
            user={craftworker}
            className={clsxm(
              !shift.published && "ring-inset-2 ring-2 ring-yellow-400",
            )}
            tooltips={false}
          />
        </div>
      </Tippy>
    </div>
  );
};

const EventContent: React.FC<{
  shifts?: Shift[];
}> = ({ shifts }) => {
  if (!shifts || shifts?.length === 0) {
    return (
      <div className="flex flex-grow flex-col items-center justify-center text-xs font-normal italic text-gray-500">
        <span>No staff assigned</span>
      </div>
    );
  }

  return (
    <div className="flex h-full w-full flex-row flex-wrap justify-center">
      {shifts?.map((shift, idx) => (
        <div className={clsxm(idx > 0 && "-ml-2")} key={shift.shiftId}>
          <ShiftContent shift={shift} />
        </div>
      ))}
    </div>
  );
};

const Timesheet: React.FC<{
  timesheet: Timesheet;
}> = ({ timesheet }) => {
  const craftworker = timesheet.craftworker;

  const start = new Date(timesheet.startedAt).toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });
  const end = new Date(timesheet.endedAt).toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });

  return (
    <li>
      <a
        href={`/timesheets/${timesheet.timesheetId}?modal=true&dark_mode=true`}
        data-turbo-frame="modal"
        className="flex gap-2 p-2"
      >
        <div className="w-full">
          {craftworker ? (
            <span className="flex items-center justify-between gap-2 truncate font-bold leading-none">
              {craftworker.firstName}
              <span className="flex gap-1">
                {craftworker.languages.includes("en") && <UserVoiceIcon />}
                {craftworker.level === "level_3" && <SprayCanIcon />}
              </span>
            </span>
          ) : null}
          <span
            className={`${timesheet.approved || timesheet.inProgress ? "text-gray-500" : "text-yellow-500"} block tabular-nums`}
          >
            {start} - {timesheet.inProgress ? "TBD" : end}
          </span>
        </div>
      </a>
    </li>
  );
};

const Shift: React.FC<{ shift: Shift }> = ({ shift }) => {
  const craftworker = shift.craftworker;

  const start = new Date(shift.start).toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });
  const end = new Date(shift.end).toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });

  return (
    <li>
      <a
        href={`/shifts/${shift.shiftId}?modal=true&dark_mode=true`}
        data-turbo-frame="modal"
        className="flex gap-2 p-2"
      >
        {craftworker && <Avatar user={craftworker} />}
        <div className="w-full">
          {craftworker ? (
            <span className="flex items-center justify-between gap-2 truncate font-bold leading-none">
              {craftworker.firstName}
              <span className="flex gap-1">
                {craftworker.languages.includes("en") && <UserVoiceIcon />}
                {craftworker.level === "level_3" && <SprayCanIcon />}
              </span>
            </span>
          ) : null}
          <span className="block text-gray-500">
            {start} - {end}
          </span>
        </div>
      </a>
    </li>
  );
};

const useDeletePlannedShiftsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (shiftIds: string[]) => {
      const response = await fetch("/shifts/bulk_destroy", {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Accept:
            "text/vnd.turbo-stream.html, text/html, application/xhtml+xml", // Request Turbo Stream response
          "X-CSRF-Token":
            document
              .querySelector('meta[name="csrf-token"]')
              ?.getAttribute("content") ?? "",
        },
        body: JSON.stringify({ ids: shiftIds }),
      });

      if (!response.ok) {
        throw new Error("Failed to delete shifts");
      }
      const turboStreamHTML = await response.text();

      // Use Turbo to process the Turbo Stream HTML
      Turbo.renderStreamMessage(turboStreamHTML);

      return turboStreamHTML;
    },
    onSuccess: () => {
      // invalidate every query that starts with /api/calendars/calendar_events
      // see: https://tanstack.com/query/latest/docs/framework/react/guides/query-invalidation#query-matching-with-invalidatequeries
      queryClient.invalidateQueries({
        queryKey: ["/api/calendars/calendar_events"],
      });
    },
    onError: (error) => {
      console.error("Error deleting shifts:", error);
    },
  });
};

const EventDropdown: React.FC<{
  event: CollatedEvent;
  craftworkers: CalendarCraftworker[];
  isPTO?: boolean;
}> = ({ event, craftworkers }) => {
  const {
    start,
    end,
    locationId,
    locationShortAddress,
    accountOwnerName,
    shifts,
    staffing,
    eventId,
    reworkReasonId,
    id,
  } = event;

  const levels = {
    LEVEL1: "level_1",
    LEVEL2: "level_2",
    LEVEL3: "level_3",
  } as const;

  const levelOneCount = craftworkers.filter(
    (cw) => cw.level === levels.LEVEL1,
  ).length;
  const levelTwoCount = craftworkers.filter((cw) =>
    cw.level.includes(levels.LEVEL2),
  ).length;

  const levelThreeCount = craftworkers.filter((cw) =>
    cw.level.includes(levels.LEVEL3),
  ).length;

  const plannedShifts = shifts.filter(
    (shift) => !shift.timesheets || shift.timesheets.length === 0,
  );

  // A shift can have many timesheets. We need all of them in a flat array of objects containing the timesheet and the craftworker
  const inProgressWork = shifts.flatMap((shift) =>
    shift.timesheets
      .filter((timesheet) => {
        return timesheet.inProgress;
      })
      .map((timesheet) => ({
        timesheet: timesheet,
        craftworker: shift.craftworker,
      })),
  );

  const completedWork = shifts.flatMap((shift) =>
    shift.timesheets
      .filter((timesheet) => !timesheet.inProgress)
      .map((timesheet) => ({
        timesheet: timesheet,
        craftworker: shift.craftworker,
      })),
  );

  const deletePlannedShiftsMutation = useDeletePlannedShiftsMutation();

  const handleClearPlannedShifts = () => {
    const plannedShiftIds = plannedShifts.map((shift) => shift.shiftId);
    deletePlannedShiftsMutation.mutate(plannedShiftIds);
  };

  const assigned = staffing.assigned ?? 0;
  const committed = staffing.committed ?? 0;

  const shiftParams = new URLSearchParams({
    location_id: locationId,
    starts_at: start,
    ends_at: end,
    event_id: eventId ?? "",
    rework_reason_id: reworkReasonId ?? "",
  });

  return (
    <motion.div
      initial={{ height: 0, opacity: 0 }}
      animate={{ height: "auto", opacity: 1 }}
      exit={{ height: 0, opacity: 0 }}
      className="shadow-dark absolute left-0 right-0 top-full z-10 space-y-4 overflow-hidden rounded-b-lg bg-plum p-2"
      id={id}
    >
      <div className="flex flex-col items-center gap-1 leading-none">
        <span className="text-md font-bold">{accountOwnerName}</span>
        <span>{locationShortAddress}</span>
        <span>
          {assigned} / {committed}
        </span>
      </div>
      {craftworkers.length > 0 && (
        <>
          <div className="flex justify-center gap-6 font-medium">
            <span>L1: {levelOneCount}</span>
            <span>L2: {levelTwoCount}</span>
            <span>L3: {levelThreeCount}</span>
          </div>
          {plannedShifts.length > 0 && (
            <div>
              <div className="mb-1 flex justify-between">
                <span className="block font-bold">Planned:</span>
                <span>
                  <button
                    onClick={handleClearPlannedShifts}
                    className="text-gray-300 hover:text-white"
                  >
                    Clear
                  </button>
                </span>
              </div>
              <ul className="max-h-[300px] divide-y overflow-y-auto rounded border bg-white text-plum">
                {plannedShifts.map((shift, i) => (
                  <Shift shift={shift} key={i} />
                ))}
              </ul>
            </div>
          )}
          {inProgressWork.length > 0 && (
            <div>
              <span className="mb-1 block font-bold">In Progress:</span>
              <ul className="max-h-[300px] divide-y overflow-y-auto rounded border bg-white text-plum">
                {inProgressWork.map((work, i) => (
                  <Timesheet timesheet={work.timesheet} key={i} />
                ))}
              </ul>
            </div>
          )}
          {completedWork.length > 0 && (
            <div>
              <span className="mb-1 block font-medium">Completed:</span>
              <ul className="max-h-[300px] divide-y overflow-y-auto rounded border bg-white text-plum">
                {completedWork.map((work, i) => (
                  <Timesheet timesheet={work.timesheet} key={i} />
                ))}
              </ul>
            </div>
          )}
        </>
      )}

      {!start && (
        <div className="btn -mt-2 w-full justify-center bg-warning text-center leading-none text-white">
          Missing Start Time
        </div>
      )}
      {!end && (
        <div className="btn -mt-2 w-full justify-center bg-warning text-center leading-none text-white">
          Missing End Time
        </div>
      )}

      {start && end && (
        <div>
          <a
            className="btn btn-white -mt-2 w-full justify-center text-center leading-none"
            href={`/shifts/new?modal=true&${shiftParams.toString()}`}
            data-turbo-frame="modal"
          >
            Add Painter
          </a>
        </div>
      )}
    </motion.div>
  );
};

type CollatedEvent = CalendarEvent & {
  id: string;
  accountOwnerName: string;
  eventId?: string | null;
  locationId: string;
  locationShortAddress: string;
  projectId?: string | null;
  reworkReasonId?: string | null;
  reworkReasonTitle?: string | null;
  shifts: Shift[];
  staffing: StaffingCounts;
  eventType: ProjectTypes | null;
};

type CollatedEventProps = {
  event: CollatedEvent;
};

const CollatedEvent: React.FC<CollatedEventProps> = ({ event }) => {
  const targetRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { params } = useRouter();
  const { style } = params;

  const { title, staffing, shifts, reworkReasonTitle, projectType } = event;
  const [showDetails, setShowDetails] = useState(false);

  const { data: allCraftworkers, isPending } = useCalendarCraftworkersQuery();

  const craftworkerIds = [
    ...new Set(shifts.map((shift) => shift.craftworkerId)),
  ];
  const craftworkers = allCraftworkers?.filter((cw) => {
    const searchId = cw?.id?.toString();
    if (!searchId) return false;
    return craftworkerIds.some((id) => id.toString() === searchId.toString());
  });

  const assigned = staffing?.assigned ?? 0;
  const committed = staffing?.committed ?? 0;
  const noStaff = assigned === 0 && committed > 0;

  const isPTO = title === "PTO" || title === "PTO 2";
  const isUPTO = title === "UPTO";
  const isProjectEvent = !isPTO && !isUPTO;
  const isCraftworkFacilityEvent = title.includes("Craftwork");

  const handleTargetClick: React.MouseEventHandler<HTMLDivElement> = (
    event,
  ) => {
    const element = event.target as HTMLElement;
    const td = element.closest("td");

    if (showDetails) {
      setShowDetails(false);
      if (td) td.style.zIndex = "unset";
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    } else {
      setShowDetails(true);
      if (td) td.style.zIndex = "999";
    }
  };

  const handleWindowClick = (event: MouseEvent) => {
    const element = event.target as HTMLElement;
    const target = targetRef.current;
    const wrapper = wrapperRef.current;

    if (
      !target ||
      !wrapper ||
      target === element ||
      (element && target.contains(element)) ||
      (element && wrapper.contains(element))
    )
      return;

    const td = target.closest("td");

    if (td && element && !td.contains(element)) {
      td.style.zIndex = "unset";
    }

    setShowDetails(false);
  };

  useEffect(() => {
    if (showDetails) {
      window.addEventListener("click", handleWindowClick);
    }
    return () => {
      window.removeEventListener("click", handleWindowClick);
    };
  }, [showDetails]);

  if (isPending) return null;

  return (
    <div
      ref={wrapperRef}
      className={clsxm(
        "relative flex h-full min-h-[68px] cursor-pointer flex-col bg-white p-2 text-sm hover:bg-plum hover:text-white",
        noStaff && "bg-yellow-50 text-warning",
        showDetails && "border-0 bg-plum text-white",
        style === "compact" && "min-h-[unset]",
      )}
    >
      <div
        ref={targetRef}
        className="flex flex-grow flex-col items-center gap-2"
        onClick={handleTargetClick}
      >
        <EventHeader
          craftworkers={craftworkers ?? []}
          staffing={staffing}
          showCount={isCraftworkFacilityEvent || !isProjectEvent}
          countClasses={clsxm(
            isPTO && "bg-notification text-white",
            isUPTO && "bg-warning text-white",
          )}
          hideBadges={!isProjectEvent}
          reworkReason={reworkReasonTitle}
          projectType={projectType}
        />
        {style !== "compact" && <EventContent shifts={shifts} />}
      </div>

      <AnimatePresence mode="popLayout">
        {showDetails && (
          <div>
            <EventDropdown
              event={event}
              craftworkers={craftworkers ?? []}
              isPTO={!isProjectEvent}
            />
          </div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default CollatedEvent;
