import { Estimate } from "@/types";
import {
  constructReactQuery,
  ConstructReactQueryConfig,
} from "@/utils/constructReactQuery";

type EstimateQueryProps = {
  estimateId: string;
};

export const useEstimateQuery = (
  { estimateId }: EstimateQueryProps,
  config?: ConstructReactQueryConfig,
) => {
  const url = `/api/v1/estimates/${estimateId}`;
  return constructReactQuery<Estimate>(url, {
    ...config,
    queryKey: ["estimate", estimateId],
  });
};
