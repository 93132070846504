import React from "react";
import {
  Bell,
  Bolt,
  Calendar,
  ChartActivity,
  ChevronRight,
  ClipboardCheck,
  Clock,
  Discount,
  House7,
  HouseBolt,
  MapPosition,
  Msgs,
  TriangleWarning,
  User,
  Users5,
  WrenchScrewdriver,
} from "@craftworkco/nucleo-icons";
import * as HeroIcons from "@heroicons/react/24/outline";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@radix-ui/react-collapsible";

import UserMenu from "@/components/UserMenu";
import useRouter from "@/hooks/useRouter";
import type { CustomView } from "@/types";

import { Badge } from "../Badge";
import { Logo } from "../Logo";
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroupLabel,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
  SidebarRail,
  useSidebar,
} from "../Sidebar";

const formatIconName = (icon: string) => {
  const pascalCase = icon
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join("");
  return pascalCase;
};

type Link = {
  name: string;
  icon: React.ReactNode;
  url?: string;
  engOnly?: boolean;
  links?: Link[];
};
const links: Link[] = [
  {
    name: "Dashboard",
    icon: <House7 />,
    url: "/",
    links: [
      { name: "Home", icon: <User />, url: "/" },
      { name: "Growth", icon: <Bolt />, url: "/sales" },
      {
        name: "Analytics",
        icon: <ChartActivity />,
        url: "/analytics",
      },
      { name: "Map", icon: <MapPosition />, url: "/map" },
    ],
  },
  { name: "Customers", icon: <Users5 />, url: "/customers" },
  { name: "Projects", icon: <ClipboardCheck />, url: "/projects/list" },
  {
    name: "Sales",
    icon: <HouseBolt />,
    links: [
      {
        name: "Check-ins",
        icon: <Clock />,
        url: "/sales/checkins",
      },
      {
        name: "Estimates",
        icon: <TriangleWarning />,
        url: "/sales/estimates_review",
        engOnly: true,
      },
    ],
  },
  { name: "Calendar", icon: <Calendar />, url: "/calendars" },
  { name: "Messages", icon: <Msgs />, url: "/conversations" },
  { name: "Notifications", icon: <Bell />, url: "/notifications" },
];
const adminLinks: Link[] = [
  {
    name: "Craftworkers",
    icon: <WrenchScrewdriver />,
    url: "/craftworkers/list",
  },
  { name: "Discounts", icon: <Discount />, url: "/discounts" },
];

const AppSidebar = ({
  engOnly = false,
  favorites = [],
  avatarUrl,
  userName,
  admin = false,
  initialPath,
  unreadCount = 2,
  logoutUrl,
  ...props
}: React.ComponentProps<typeof Sidebar> & {
  engOnly?: boolean;
  favorites?: CustomView[];
  avatarUrl: string;
  userName: string;
  admin?: boolean;
  initialPath: string;
  unreadCount?: number;
  logoutUrl: string;
}) => {
  const { open, toggleSidebar } = useSidebar();
  const { pathname } = useRouter();
  const currentPath = pathname ? pathname : initialPath;

  return (
    <Sidebar collapsible="icon" className="h-full" {...props}>
      <SidebarHeader>
        <div
          className="mb-4 h-7 shrink-0 cursor-pointer items-center overflow-hidden px-1 hover:opacity-80"
          onClick={toggleSidebar}
        >
          <Logo size="sm" />
        </div>
      </SidebarHeader>
      <SidebarContent className="px-2">
        <SidebarMenu>
          {links
            .filter((link) => (link.engOnly ? engOnly : true))
            .map((link) =>
              link.links ? (
                <Collapsible
                  asChild
                  defaultOpen={link.links
                    .filter((sublink) => !sublink.engOnly)
                    .some((sublink) => currentPath === sublink.url)}
                  className="group/collapsible"
                  key={link.name}
                >
                  <SidebarMenuItem>
                    <CollapsibleTrigger asChild>
                      <SidebarMenuButton tooltip={link.name}>
                        {link.icon}
                        <span>{link.name}</span>
                        <ChevronRight
                          size={12}
                          className="ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90"
                        />
                      </SidebarMenuButton>
                    </CollapsibleTrigger>
                    <CollapsibleContent>
                      <SidebarMenuSub>
                        {link.links
                          .filter((sublink) => !sublink.engOnly)
                          .map((sublink) => (
                            <SidebarMenuSubItem key={sublink.name}>
                              <SidebarMenuSubButton
                                asChild
                                isActive={currentPath === sublink.url}
                                tooltip={sublink.name}
                              >
                                <a href={sublink.url}>
                                  {sublink.icon}
                                  <span className="truncate">
                                    {sublink.name}
                                  </span>
                                </a>
                              </SidebarMenuSubButton>
                            </SidebarMenuSubItem>
                          ))}
                      </SidebarMenuSub>
                    </CollapsibleContent>
                  </SidebarMenuItem>
                </Collapsible>
              ) : (
                <SidebarMenuItem key={link.name}>
                  <SidebarMenuButton
                    asChild
                    isActive={currentPath === link.url}
                    tooltip={link.name}
                  >
                    <a href={link.url}>
                      {link.icon}
                      <span className="truncate">{link.name}</span>
                      {link.name === "Inbox" && unreadCount > 0 && (
                        <Badge className="ml-auto bg-red-200 px-1.5 text-red-900">
                          {unreadCount}
                        </Badge>
                      )}
                    </a>
                  </SidebarMenuButton>
                </SidebarMenuItem>
              ),
            )}
        </SidebarMenu>
        <SidebarGroupLabel>
          <a
            href="custom_views"
            className="cursor-pointer text-current hover:opacity-80 group-data-[collapsible=icon]:hidden"
          >
            Favorites
          </a>
        </SidebarGroupLabel>
        <SidebarMenu>
          {favorites?.map((favorite) => {
            const iconName = `${formatIconName(favorite.icon)}Icon`;
            const IconComponent =
              HeroIcons[iconName as keyof typeof HeroIcons] ??
              HeroIcons.QuestionMarkCircleIcon;
            return (
              <SidebarMenuItem key={favorite.id}>
                <SidebarMenuButton
                  asChild
                  isActive={currentPath === favorite.url}
                  tooltip={favorite.name}
                >
                  <a href={favorite.url}>
                    {IconComponent && <IconComponent />}
                    <span className="truncate">{favorite.name}</span>
                  </a>
                </SidebarMenuButton>
              </SidebarMenuItem>
            );
          })}
        </SidebarMenu>
        <SidebarGroupLabel>Admin</SidebarGroupLabel>
        <SidebarMenu>
          {adminLinks.map((link) => (
            <SidebarMenuItem key={link.name}>
              <SidebarMenuButton
                asChild
                isActive={currentPath === link.url}
                tooltip={link.name}
              >
                <a href={link.url}>
                  {link.icon}
                  <span className="truncate">{link.name}</span>
                </a>
              </SidebarMenuButton>
            </SidebarMenuItem>
          ))}
        </SidebarMenu>
      </SidebarContent>
      <SidebarFooter>
        <UserMenu
          avatarUrl={avatarUrl}
          userName={userName}
          admin={admin}
          engOnly={engOnly}
          collapsed={!open}
          logoutUrl={logoutUrl}
        />
      </SidebarFooter>
      <SidebarRail />
    </Sidebar>
  );
};

export default AppSidebar;
