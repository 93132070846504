// See the Tailwind default theme values here:
// https://github.com/tailwindcss/tailwindcss/blob/master/stubs/defaultConfig.stub.js
import "@tailwindcss/aspect-ratio";
import "@tailwindcss/typography";
import "flowbite/plugin";
import "@tailwindcss/container-queries";

import defaultTheme from "tailwindcss/defaultTheme";

const { fontFamily } = defaultTheme;

const PRIMARY_PURPLE = "#58117d";

export const baseColors = {
  black: {
    DEFAULT: "#000000",
  },
  white: {
    DEFAULT: "#ffffff",
  },
  denim: {
    DEFAULT: "#6ea7b8",
  },
  gray: {
    DEFAULT: "#808080",
    50: "#F5F5F5",
    100: "#f2f2f2",
    200: "#e6e6e6",
    300: "#cccccc",
    400: "#b3b3b3",
    500: "#808080",
    600: "#6d6869",
    700: "#595052",
    800: "#4a4047",
    900: "#3a303d",
  },
  red: {
    DEFAULT: "#f05252",
    50: "#fdf2f2",
    100: "#fde8e8",
    200: "#fbd5d5",
    300: "#f8b4b4",
    400: "#f98080",
    500: "#f05252",
    600: "#e02424",
    700: "#c81e1e",
    800: "#9b1c1c",
    900: "#771d1d",
  },
  orange: {
    DEFAULT: "#ff5a1f",
    50: "#fff8f1",
    100: "#feecdc",
    200: "#fcd9bd",
    300: "#fdba8c",
    400: "#ff8a4c",
    500: "#ff5a1f",
    600: "#d03801",
    700: "#b43403",
    800: "#8a2c0d",
    900: "#771d1d",
  },
  yellow: {
    DEFAULT: "#f8b13e",
    50: "#faf6eb",
    100: "#fae8c8",
    200: "#f9daa6",
    300: "#f9cd83",
    400: "#f8bf61",
    500: "#f8b13e",
    600: "#d0922f",
    700: "#a77421",
    800: "#7f5512",
    900: "#563603",
  },
  green: {
    DEFAULT: "#7edd3f",
    50: "#f1fbeb",
    100: "#e0f5d3",
    200: "#c8efae",
    300: "#afe989",
    400: "#97e364",
    500: "#7edd3f",
    600: "#67b732",
    700: "#519126",
    800: "#3a6b19",
    900: "#23450c",
  },
  teal: {
    DEFAULT: "#028998",
    50: "#eaf9f9",
    100: "#cff4f5",
    200: "#a5e9ec",
    300: "#72d7de",
    400: "#13b5c3",
    500: "#028998",
    600: "#026976",
    700: "#015b67",
    800: "#054652",
    900: "#003c47",
  },
  blue: {
    DEFAULT: "#0676f1",
    50: "#f3f8fe",
    100: "#c6e0fc",
    200: "#96c5f9",
    300: "#66abf7",
    400: "#3690f4",
    500: "#0676f1",
    600: "#0565ce",
    700: "#0555ac",
    800: "#044489",
    900: "#033366",
  },
  plum: {
    DEFAULT: "#32213b",
    50: "#f7f5f7",
    100: "#ece9ed",
    200: "#ded9e0",
    300: "#d1cad4",
    400: "#c3bac7",
    500: "#b6aaba",
    600: "#95889a",
    700: "#74667b",
    800: "#53435b",
    900: "#32213b",
  },
  pink: {
    DEFAULT: "#d61f69",
    50: "#fdf2f8",
    100: "#fce8f3",
    200: "#fad1e8",
    300: "#f8b4d9",
    400: "#f17eb8",
    500: "#e74694",
    600: "#d61f69",
    700: "#bf125d",
    800: "#99154b",
    900: "#751a3d",
  },
};

export const semanticColors = {
  primary: {
    DEFAULT: baseColors.plum,
    foreground: "#fbf7fe",
    50: "#fbf7fe",
    100: "#f5e8fc",
    200: "#dbc4e7",
    300: "#c1a0d2",
    400: "#a77dbd",
    500: "#8c59a7",
    600: "#723592",
    700: PRIMARY_PURPLE,
    800: "#45195c",
    900: "#32213b",
  },
  secondary: {
    DEFAULT: PRIMARY_PURPLE,
    foreground: baseColors.white,
  },
  tertiary: {
    DEFAULT: baseColors.gray[200],
    foreground: baseColors.gray[700],
  },
  interior: {
    DEFAULT: PRIMARY_PURPLE,
    foreground: baseColors.white,
  },
  exterior: {
    DEFAULT: baseColors.blue[700],
    foreground: baseColors.white,
  },
  cabinets: {
    DEFAULT: baseColors.teal[700],
    foreground: baseColors.white,
  },
  specialty: {
    DEFAULT: baseColors.teal[900],
    foreground: baseColors.white,
  },
  link: {
    DEFAULT: baseColors.white,
    foreground: PRIMARY_PURPLE,
  },
  notification: {
    DEFAULT: baseColors.blue[500],
    foreground: baseColors.white,
  },
  alert: {
    DEFAULT: baseColors.red[500],
    foreground: baseColors.white,
  },
  warning: {
    DEFAULT: baseColors.yellow[500],
    foreground: baseColors.white,
  },
  success: {
    DEFAULT: baseColors.green[500],
    foreground: baseColors.white,
  },
};

const componentColors = {
  background: baseColors.white,
  foreground: baseColors.black,
  card: {
    DEFAULT: baseColors.white,
    foreground: baseColors.black,
  },
  popover: {
    DEFAULT: baseColors.white,
    foreground: baseColors.black,
  },
  muted: {
    DEFAULT: baseColors.gray[100], // Primary 50
    foreground: baseColors.gray[500],
  },
  accent: {
    DEFAULT: baseColors.gray[50], // Primary 50
    foreground: PRIMARY_PURPLE,
  },
  destructive: {
    DEFAULT: baseColors.red[500],
    foreground: baseColors.white,
  },
  border: baseColors.gray[200],
  input: baseColors.gray[200],
  ring: PRIMARY_PURPLE,
  sidebar: {
    DEFAULT: baseColors.white.DEFAULT,
    foreground: baseColors.gray[900],
    primary: baseColors.plum.DEFAULT,
    "primary-foreground": baseColors.white.DEFAULT,
    accent: baseColors.gray[50],
    "accent-foreground": baseColors.gray[900],
    border: baseColors.gray[200],
    ring: baseColors.plum.DEFAULT,
  },
  tooltip: {
    DEFAULT: baseColors.white.DEFAULT,
    foreground: baseColors.gray[900],
    primary: baseColors.plum.DEFAULT,
    "primary-foreground": baseColors.white.DEFAULT,
  },
};

export const theme = {
  container: {
    center: true,
    padding: {
      DEFAULT: "16px",
    },
  },
  screens: {
    sm: "640px",
    md: "768px",
    lg: "1024px",
    xl: "1280px",
    "2xl": "1563px",
  },
  spacing: {
    0: "0rem",
    px: "0.0625rem",
    0.5: "0.125rem",
    1: "0.25rem",
    1.5: "0.375rem",
    2: "0.5rem",
    2.5: "0.625rem",
    3: "0.75rem",
    3.5: "0.875rem",
    4: "1rem",
    5: "1.25rem",
    6: "1.5rem",
    7: "1.75rem",
    8: "2rem",
    9: "2.25rem",
    10: "2.5rem",
    11: "2.75rem",
    12: "3rem",
    14: "3.5rem",
    16: "4rem",
    20: "5rem",
    24: "6rem",
    28: "7rem",
    32: "8rem",
    36: "9rem",
    40: "10rem",
    44: "11rem",
    48: "12rem",
    52: "13rem",
    56: "14rem",
    60: "15rem",
    64: "16rem",
    72: "18rem",
    80: "20rem",
    96: "24rem",
  },
  borderRadius: {
    none: "0",
    sm: "2px",
    DEFAULT: "4px",
    md: "6px",
    lg: "8px",
    xl: "12px",
    "2xl": "16px",
    "3xl": "24px",
    full: "9999px",
  },
  extend: {
    maxWidth: {
      xs: "320px",
      sm: "384px",
      md: "448px",
      lg: "512px",
      xl: "576px",
      "2xl": "672px",
      "3xl": "768px",
      "4xl": "896px",
      "5xl": "1024px",
      "6xl": "1152px",
      "7xl": "1280px",
    },
    colors: {
      ...baseColors,
      ...semanticColors,
      ...componentColors,
    },
    fontFamily: {
      sans: ["Inter", ...fontFamily.sans],
      mono: [
        "ui-monospace",
        "SFMono-Regular",
        "Menlo",
        "Monaco",
        "Consolas",
        "monospace",
      ],
      default: ["Inter", ...fontFamily.sans],
      regular: ["Inter", ...fontFamily.sans],
      medium: ["Inter", ...fontFamily.sans],
      semiBold: ["Inter", ...fontFamily.sans],
      bold: ["Inter", ...fontFamily.sans],
      title: ["Parafina", ...fontFamily.serif],
    },
    fontSize: {
      xs: "0.75rem",
      sm: "0.875rem",
      base: "1rem",
      lg: "1.125rem",
      xl: "1.25rem",
      "2xl": "1.5rem",
      "3xl": "1.875rem",
      "4xl": "2.25rem",
      "5xl": "3rem",
      "6xl": "3.75rem",
      "7xl": "4.5rem",
      "8xl": "6rem",
      "9xl": "8rem",
      caption: "0.75rem",
      "caption-sm": "0.625rem",
    },
    leading: {
      none: "0",
      default: "1.5",
      tight: "1.25",
      relaxed: "1.625",
      loose: "2",
    },
    boxShadow: {
      sm: "0px 1px 2px rgba(0, 0, 0, 0.08)",
      DEFAULT:
        "0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px -1px rgba(0, 0, 0, 0.1)",
      md: "0px 2px 4px -2px rgba(0, 0, 0, 0.05), 0px 4px 6px -1px rgba(0, 0, 0, 0.1)",
      lg: "0px 4px 6px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1)",
      xl: "0px 10px 10px rgba(0, 0, 0, 0.04), 0px 20px 25px -5px rgba(0, 0, 0, 0.1)",
      "2xl": "0px 25px 50px -12px rgba(0, 0, 0, 0.25)",
    },
    borderRadius: {
      sm: "2px",
      DEFAULT: "4px",
      md: "6px",
      lg: "8px",
      xl: "12px",
      "2xl": "16px",
      "3xl": "24px",
      full: "9999px",
    },
    aspectRatio: {
      square: "1 / 1",
      landscape: "4 / 3",
      portrait: "3 / 4",
      tall: "2 / 3",
      wide: "2 / 3",
    },
    backgroundSize: {
      auto: "auto",
      cover: "cover",
      contain: "contain",
      105: "105% cover",
      110: "110% cover",
      125: "125% cover",
      150: "150% cover",
    },
  },
  popover: {
    DEFAULT: baseColors.white.DEFAULT,
    foreground: baseColors.gray[900],
    primary: baseColors.plum.DEFAULT,
    "primary-foreground": baseColors.white.DEFAULT,
  },
  select: {
    DEFAULT: baseColors.white.DEFAULT,
    foreground: baseColors.gray[900],
    primary: baseColors.plum.DEFAULT,
    "primary-foreground": baseColors.white.DEFAULT,
    accent: baseColors.gray[50],
    "accent-foreground": baseColors.gray[900],
  },
  badge: {
    primary: baseColors.plum.DEFAULT,
    secondary: baseColors.gray[100],
    destructive: baseColors.yellow[400],
  },
};

/** @type {import('tailwindcss').Config} */
export default {
  darkMode: "false",
  plugins: [
    "@tailwindcss/aspect-ratio",
    "@tailwindcss/typography",
    "flowbite/plugin",
    "@tailwindcss/container-queries",
    function ({
      addVariant,
    }: {
      addVariant: (name: string, pattern: string) => void;
    }) {
      addVariant("child", "& > *");
      addVariant("not-first", "& > *:not(:first-child)");
      addVariant("child-hover", "& > *:hover");
    },
  ],

  content: [
    "./app/helpers/**/*.rb",
    "./app/components/**/*.rb",
    "./app/components/**/*.erb",
    "./app/javascript/**/*.js",
    "./app/javascript/**/*.tsx",
    "./app/javascript/**/*.jsx",
    "./app/views/**/*.erb",
    "./app/views/**/*.haml",
    "./app/views/**/*.slim",
    "./lib/jumpstart/app/views/**/*.erb",
    "./lib/jumpstart/app/helpers/**/*.rb",
    "./node_modules/flowbite/**/*.js",
    "./emails/*.tsx",
    "./react-email/**/*.tsx",
  ],
  safelist: [
    "bg-warning",
    "bg-notification",
    "h-[16px]",
    "bg-green-100",
    "bg-green-200",
    "bg-green-300",
    "bg-green-500",
    "bg-yellow-100",
    "bg-yellow-200",
    "bg-yellow-300",
    "bg-yellow-500",
    "bg-red-100",
    "bg-red-200",
    "bg-red-300",
    "bg-red-500",
    "sidebar",
    "sidebar-primary",
    "sidebar-primary-foreground",
    "sidebar-accent",
    "sidebar-accent-foreground",
    "sidebar-border",
    "sidebar-ring",
    "tooltip",
    "tooltip-primary",
    "tooltip-primary-foreground",
    "tooltip-border",
    "tooltip-ring",
    "tooltip-foreground",
    "tooltip-background",
    "background",
    "foreground",
    "primary",
    "primary-foreground",
    "secondary",
    "secondary-foreground",
    "tertiary",
    "card",
    "card-foreground",
    "popover",
    "popover-foreground",
    "muted",
    "accent",
    "accent-foreground",
    "destructive",
    "destructive-foreground",
    "border",
    "input",
    "ring",
  ],
  theme,
  Future: {},
};
