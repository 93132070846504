import React from "react";

import { clsxm } from "@/utils/clsxm";

import { Badge } from "../ui/Badge";
import { Tooltip, TooltipContent, TooltipTrigger } from "../ui/Tooltip";

const statuses = {
  draft: {
    backgroundColor: "bg-gray-100",
    textColor: "text-gray-900",
    label: "Draft",
    tooltip: "Draft",
  },
  internal_review: {
    backgroundColor: "bg-notification",
    textColor: "text-white",
    label: "Internal Review",
    tooltip: "Internal Review",
  },
  pending_customer_approval: {
    backgroundColor: "bg-warning",
    textColor: "text-white",
    label: "Pending",
    tooltip: "Pending Customer Approval",
  },
  approved: {
    backgroundColor: "bg-success",
    textColor: "text-white",
    label: "Approved",
    tooltip: "Approved",
  },
  rejected_by_customer: {
    backgroundColor: "bg-alert",
    textColor: "text-white",
    label: "Rejected",
    tooltip: "Rejected by Customer",
  },
  rejected_by_internal: {
    backgroundColor: "bg-alert",
    textColor: "text-white",
    label: "Rejected",
    tooltip: "Rejected by Internal",
  },
  internally_approved: {
    backgroundColor: "bg-success",
    textColor: "text-white",
    label: "Internally Approved",
    tooltip: "Internally Approved",
  },
} as const;
export type EstimateStatus = keyof typeof statuses;

const EstimateStatusBadge = ({ status }: { status: EstimateStatus }) => {
  return (
    <Tooltip>
      <TooltipTrigger>
        <Badge
          className={clsxm(
            "shadow-none",
            statuses[status].backgroundColor,
            statuses[status].textColor,
          )}
        >
          {statuses[status].label}
        </Badge>
      </TooltipTrigger>
      <TooltipContent>{statuses[status].tooltip}</TooltipContent>
    </Tooltip>
  );
};

export default EstimateStatusBadge;
