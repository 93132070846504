import React from "react";

import { Avatar, AvatarFallback, AvatarImage } from "../ui/Avatar";
import { Tooltip, TooltipContent, TooltipTrigger } from "../ui/Tooltip";

const CraftworkerAvatar = ({
  name,
  avatarUrl,
  prefixId,
}: {
  name: string;
  avatarUrl: string;
  prefixId: string;
}) => {
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <a href={`/craftworkers/${prefixId}`} target="_blank" rel="noreferrer">
          <Avatar className="h-6 w-6">
            <AvatarImage src={avatarUrl} />
            <AvatarFallback>{name[0]}</AvatarFallback>
          </Avatar>
        </a>
      </TooltipTrigger>
      <TooltipContent>{name}</TooltipContent>
    </Tooltip>
  );
};

export default CraftworkerAvatar;
