import { enter, leave, toggle } from "el-transition";
import { Dropdown } from "tailwindcss-stimulus-components";

export default class extends Dropdown {
  static targets = ["menu"];
  static values = { maxHeight: String };

  connect() {
    this.menus = document.querySelectorAll('[data-dropdown-target="menu"]');
    this.ancestorDropdown = null;
    this.previousClone = null;
  }

  /**
   * Toggle the dropdown menu when the trigger is clicked.
   * @param {Event} event - The event object.
   */
  toggle(event) {
    this.ancestorDropdown = this.findAncestorDropdown();
    this.previousClone = this.ancestorDropdown
      ? this.ancestorDropdown.querySelector("[data-submenu-clone]")
      : this.element.querySelector("[data-submenu-clone]");

    // Hide all other dropdowns present in the DOM.
    // Only one menu should be visible at a time.
    this.menus?.forEach((menu) => {
      // Check if the current menu is the target or if it contains the event.target
      if (
        menu !== this.menuTarget &&
        !menu.contains(event.target) &&
        !menu.classList.contains("hidden")
      ) {
        // close the menu with a nice animation
        leave(menu);
      }
    });

    // Open the menu if it is closed
    if (this.menuTarget.classList.contains("hidden")) {
      // If this is not a submenu, we can just open the menu and place it
      if (!this.ancestorDropdown) {
        this.setPlacement(this.menuTarget);
        return;
      }

      // If this is a submenu, we need to clone the menu and append it to the parent dropdown
      if (!this.previousClone) {
        this.appendSubMenu();
        return;
      }

      // If this is a submenu and the previous clone is different from the current menu
      // we need to remove the previous clone and append the new one
      this.previousClone.remove();
      if (this.previousClone.innerHTML !== this.menuTarget.innerHTML) {
        this.appendSubMenu();
      }
      return;
    }

    // this menu is already open, so we need to close it
    if (!this.menuTarget.contains(event.target)) {
      leave(this.menuTarget); // Hide the menu
    }
    if (this.previousClone) {
      this.previousClone.remove();
    }
  }

  /**
   * Hide the dropdown menu, only used onBlur.
   * @param {Event} event - The event object.
   */
  hide(event) {
    if (
      !this.menuTarget.classList.contains("hidden") &&
      !this.element.contains(event.target) &&
      !this.menuTarget.contains(event.target)
    ) {
      this.previousClone = this.element.querySelector("[data-submenu-clone]");

      if (this.previousClone) {
        this.previousClone.remove();
      }

      leave(this.menuTarget);
    }
  }

  /**
   * Determine whether or not `this` is a submenu.
   */
  findAncestorDropdown() {
    let ancestor = this.element.parentElement;
    while (ancestor) {
      if (ancestor.dataset.controller === "dropdown") {
        return ancestor;
      }
      ancestor = ancestor.parentElement;
      this.ancestorDropdown = ancestor;
    }
    return null;
  }

  /**
   * Clone the menu and append it to the parent dropdown.
   */
  appendSubMenu() {
    this.clonedMenu = this.menuTarget.cloneNode(true);
    this.clonedMenu.dataset.submenuClone = "true"; // Add data attribute

    const parentDropdown = this.ancestorDropdown;
    if (!parentDropdown) {
      console.error("No parent dropdown found.");
      return;
    }

    parentDropdown.appendChild(this.clonedMenu);

    // Set the placement of the cloned menu
    this.setPlacement(this.clonedMenu, true);
  }

  /**
   * Determine where to place a dropdown menu based on available space
   * and the dimensions of the menu and trigger element,
   * and whether or not this is a submenu
   */
  setPlacement(menuTarget, isSubMenu = false) {
    // Remove the hidden class to get the dropdown dimensions
    enter(menuTarget);

    // Calculate available space and element dimensions
    const triggerRect = this.element.getBoundingClientRect();

    const spaceAbove = triggerRect.top;
    const spaceBelow = window.innerHeight - triggerRect.bottom;
    const spaceLeft = triggerRect.left;
    const spaceRight = window.innerWidth - triggerRect.right;

    const defaultMenuHeight = menuTarget.offsetHeight;
    const menuWidth = menuTarget.offsetWidth;

    // Determine placement based on available space
    const placementY =
      spaceBelow > defaultMenuHeight || spaceBelow > spaceAbove
        ? "below"
        : "above";
    const placementX =
      spaceRight > menuWidth || spaceRight > spaceLeft ? "right" : "left";

    // Set padding in px
    const pad = 16;

    // Calculate and set the max-height of the menu
    this.calculateMenuHeight(menuTarget, triggerRect, pad, placementY);

    // Position the menu
    this.placeMenu(
      menuTarget,
      triggerRect,
      pad,
      placementY,
      placementX,
      isSubMenu,
    );
  }

  /**
   * Calculate the max-height of the menu in px, based on available space
   * and positioning of the menu relative to the trigger element.
   * @param {HTMLElement} menuTarget - The dropdown menu element.
   * @param {DOMRect} triggerRect - The bounding rectangle of the trigger element.
   * @param {number} pad - The padding in px.
   * @param {string} placementY - The vertical placement of the menu.
   */
  calculateMenuHeight(menuTarget, triggerRect, pad, placementY) {
    const spaceAbove = triggerRect.top;
    const spaceBelow = window.innerHeight - triggerRect.bottom;

    const maxMenuHeight = this.maxHeightValue === "true" ? 500 : "none";
    const space = (placementY === "below" ? spaceBelow : spaceAbove) - pad;
    const maxHeight =
      maxMenuHeight === "none" ? space : Math.min(maxMenuHeight, space);

    menuTarget.style.maxHeight = `${maxHeight}px`;
  }

  /**
   * Given the dimensions of the menu and trigger element,
   * and the placement of the menu relative to the trigger element,
   * position the menu onscreen
   * @param {HTMLElement} menuTarget - The dropdown menu element.
   * @param {DOMRect} triggerRect - The bounding rectangle of the trigger element.
   * @param {number} pad - The padding in px.
   * @param {string} placementY - The vertical placement of the menu.
   * @param {string} placementX - The horizontal placement of the menu.
   * @param {boolean} isSubMenu - Whether or not this is a submenu.
   */
  placeMenu(
    menuTarget,
    triggerRect,
    pad,
    placementY,
    placementX,
    isSubMenu = false,
  ) {
    const dropdownHeight = menuTarget.offsetHeight;
    const dropdownWidth = menuTarget.offsetWidth;

    if (isSubMenu) {
      // Absolute positioning for submenus
      let top, left;

      if (placementY === "above") {
        top = triggerRect.top - dropdownHeight + triggerRect.height - 4;
      } else {
        top = triggerRect.top - 4;
      }

      if (placementX === "left") {
        left = triggerRect.left - dropdownWidth - pad / 2;
      } else {
        left = triggerRect.left + triggerRect.width + pad / 2;
      }

      menuTarget.style.top = `${top}px`;
      menuTarget.style.left = `${left}px`;
    } else {
      // Fixed positioning for normal menus
      let top, left;

      if (placementY === "above") {
        top = triggerRect.top - dropdownHeight - pad;
      } else {
        top = triggerRect.bottom + pad;
      }

      if (placementX === "left") {
        left = triggerRect.right - dropdownWidth;
      } else {
        left = triggerRect.left;
      }

      menuTarget.style.top = `${top}px`;
      menuTarget.style.left = `${left}px`;
      menuTarget.style.transform = "none"; // Remove any transform
    }
  }
}
