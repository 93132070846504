import type { UseQueryOptions } from "@tanstack/react-query";

import { useApiInfiniteQuery } from "@/api/openapi";
import type { GetParams, GetSuccessResponse } from "@/types/api/util";

const useTasks = (
  params: GetParams<"/api/v2/tasks">,
  options?: Omit<
    UseQueryOptions<GetSuccessResponse<"/api/v2/tasks">>,
    "queryKey" | "queryFn"
  >,
) => {
  return useApiInfiniteQuery("/api/v2/tasks", {
    params,
    ...options,
  });
};

export default useTasks;
