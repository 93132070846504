import * as React from "react";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "sonner";

import { useApiMutation } from "@/api/openapi";
import type { CalendarCraftworker, CraftworkerRole } from "@/types";
import { clsxm } from "@/utils/clsxm";

import { Avatar, AvatarFallback, AvatarImage } from "../ui/Avatar";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItemWithAvatar,
  SelectLabel,
  SelectTrigger,
} from "../ui/SelectDropdown";

export function CraftworkerSelect({
  selected,
  craftworkerOptions,
  resourceId,
  projectId,
  setRole,
  compact,
}: {
  selected?: CalendarCraftworker;
  craftworkerOptions: CalendarCraftworker[];
  resourceId: string;
  projectId: string;
  setRole: CraftworkerRole;
  compact?: boolean;
}) {
  const [showSpinner, setShowSpinner] = React.useState(false);

  const queryClient = useQueryClient();
  const { mutate: createMembership } = useApiMutation(
    "/api/v2/project_crew_memberships",
    undefined,
    "post",
    {
      onSuccess: () => {
        toast.success(`Prod. Lead Assigned`);
        setShowSpinner(false);
      },
      onError: () => {
        toast.error(`Failed to assign Prod. Lead`);
        setShowSpinner(false);
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: ["/api/calendars/projects"],
        });
      },
    },
  );

  const { mutate: updateMembership } = useApiMutation(
    "/api/v2/project_crew_memberships",
    "",
    "put",
    {
      onSuccess: () => {
        toast.success(`Prod. Lead Updated`);
        setShowSpinner(false);
      },
      onError: () => {
        toast.error(`Failed to update Prod. Lead`);
        setShowSpinner(false);
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: ["/api/calendars/projects"],
        });
      },
    },
  );

  function handleSetRole(value: string) {
    setShowSpinner(true);
    const craftworkerId = craftworkerOptions
      .find((cw) => cw.name === value)
      ?.id.toString();

    if (!craftworkerId) return;

    if (!selected) {
      createMembership({
        projectId,
        craftworkerId,
        role: setRole,
      });
    } else {
      updateMembership({
        projectId,
        craftworkerId,
        prevCraftworkerId: selected.id.toString(),
        role: setRole,
      });
    }
  }

  return (
    <Select onValueChange={handleSetRole} value={selected?.name}>
      <SelectTrigger
        noIcon
        className={clsxm(
          "my-0.5 ml-0.5 mr-2 h-8 w-fit rounded-md p-0.5",
          selected &&
            "w-full border-none text-gray-600 shadow-none hover:border-none hover:bg-gray-100 hover:text-black focus:border-none focus:bg-gray-100 focus:text-black focus:outline-none focus:ring-0 data-[state=open]:bg-gray-100 data-[state=open]:text-black",
          compact && "h-5",
        )}
      >
        {selected ? (
          <div
            className={clsxm(
              "flex flex-row items-center justify-start gap-2 rounded-sm",
              compact && "gap-1",
            )}
          >
            <div className={clsxm("relative h-6 w-6", compact && "h-4 w-4")}>
              <Avatar
                className={clsxm(
                  "flex h-6 w-6 items-center justify-center",
                  compact && "h-4 w-4",
                )}
              >
                <AvatarImage src={selected.avatarUrl} />
                <AvatarFallback>{selected.name[0]}</AvatarFallback>
              </Avatar>
              {showSpinner && (
                <div className="absolute inset-0 z-10 flex items-center justify-center">
                  <div
                    className={clsxm(
                      "h-6 w-6 animate-spin rounded-full border-2 border-plum border-t-transparent",
                      compact && "h-4 w-4",
                    )}
                  />
                </div>
              )}
            </div>

            <div className="text-left leading-[13px]">
              <p className={clsxm("font-medium text-sm", compact && "text-xs")}>
                {selected.name}
              </p>
              {!compact && (
                <p className="text-xs font-light">Production Lead</p>
              )}
            </div>
          </div>
        ) : (
          <div className={clsxm("m-1 bg-white")}>
            <p
              className={clsxm("px-1 font-regular text-xs", compact && "px-0")}
            >
              Assign Lead
            </p>
          </div>
        )}
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectLabel>Production Lead</SelectLabel>
          {craftworkerOptions.map((cw, i) => {
            return (
              <SelectItemWithAvatar
                key={`${resourceId}-option-${i}`}
                value={cw.name}
                avatarUrl={cw.avatarUrl ?? undefined}
                className="flex w-full items-center"
              >
                {cw.name}
              </SelectItemWithAvatar>
            );
          })}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}
