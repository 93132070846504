import { useApiQuery } from "@/api/openapi";

const useProjectMedia = (projectId?: string) => {
  return useApiQuery(
    "/api/v2/project_media/{id}",
    {
      id: projectId,
    },
    {
      enabled: Boolean(projectId),
    },
  );
};

export default useProjectMedia;
