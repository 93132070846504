"use client";

import React from "react";
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import {
  ChevronDownIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/16/solid";
import clsx from "clsx";

import { Avatar } from "../ui/Avatars";

type User = {
  id: number;
  name: string;
  avatarUrl: string;
  firstName: string;
  lastName: string;
};

export type Craftworker = {
  id: number;
  name: string;
  user: User;
  avatarUrl: string;
  totalHours: number;
  normalHours: number;
  overtimeHours: number;
  totalTimesheets: number;
  needsApproval: boolean;
  selected: boolean;
};

type CraftworkerListProps = {
  craftworkers: Craftworker[];
};

export function CraftworkerList({ craftworkers: cws }: CraftworkerListProps) {
  const craftworkers = cws.filter(Boolean);
  const allSelected = craftworkers.every(
    (craftworker) => !craftworker.selected,
  );
  const options = craftworkers.filter(Boolean).map((craftworker) => ({
    label: craftworker.name,
    value: craftworker.id,
    name: craftworker.name,
    selected: craftworker.selected,
  }));
  const selected = options.find((opt) => opt.selected);
  const handleSelect = (id?: number | string) => {
    const params = new URLSearchParams(window.location.search);
    if (id) {
      params.set("craftworker", id.toString());
    } else {
      params.delete("craftworker");
    }
    window.location.href = `${window.location.pathname}?${params}`;
  };
  return (
    <>
      <div className="flex flex-col overflow-y-auto border-b border-gray-100 md:hidden">
        <Listbox value={selected} onChange={(opt) => handleSelect(opt.value)}>
          <ListboxButton
            className={clsx(
              "relative flex h-9 w-full items-center justify-between px-3 py-1.5 text-left text-sm",
              "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2",
            )}
          >
            {selected?.name ?? "All"}
            <ChevronDownIcon
              className="group pointer-events-none size-4 fill-plum"
              aria-hidden="true"
            />
          </ListboxButton>
          <ListboxOptions
            anchor="bottom"
            transition
            className={clsx(
              "w-[var(--button-width)] border bg-white p-1 [--anchor-gap:var(--spacing-1)] focus:outline-none",
              "transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0",
            )}
          >
            <ListboxOption
              key="all"
              value={{ label: "All", value: "" }}
              className={clsx(
                "group flex cursor-default select-none items-center gap-2 rounded-md px-3 py-1.5 data-[focus]:bg-gray-100",
                !selected && "bg-plum text-white",
              )}
            >
              <Avatar user={{ firstName: "All", lastName: "" }} />
              <div className="text-sm">All</div>
            </ListboxOption>
            {craftworkers.map((craftworker) => {
              if (!craftworker.name) return null;
              return (
                <ListboxOption
                  key={craftworker.name}
                  value={{ label: craftworker.name, value: craftworker.id }}
                  className={clsx(
                    "group flex cursor-default select-none items-center gap-2 rounded-md px-3 py-1.5 data-[focus]:bg-gray-100",
                    craftworker.selected && "bg-plum text-white",
                  )}
                >
                  <Avatar
                    user={{
                      ...craftworker.user,
                      avatarUrl:
                        craftworker?.avatarUrl ?? craftworker?.user?.avatarUrl,
                    }}
                  />
                  <div className="text-sm">{craftworker.name}</div>
                </ListboxOption>
              );
            })}
          </ListboxOptions>
        </Listbox>
      </div>
      <div className="hidden flex-col overflow-y-auto md:flex">
        <ul className="w-full overflow-auto">
          <li key="all" className="border-b">
            <button
              onClick={() => handleSelect()}
              className={`flex w-full items-center gap-2 p-2 hover:bg-gray-100 ${allSelected && "group bg-gray-50"}`}
            >
              <Avatar user={{ firstName: "All", lastName: "" }} />
              <div className="w-full">
                <span className="flex items-center justify-between gap-2 truncate font-bold leading-none">
                  All
                </span>
              </div>
            </button>
          </li>
          {craftworkers.map((craftworker) => (
            <li key={craftworker.id} className="border-b">
              <button
                onClick={() => {
                  handleSelect(craftworker.id);
                }}
                className={`flex w-full gap-2 p-2 hover:bg-gray-100 ${craftworker.selected && "group bg-gray-50"}`}
              >
                <Avatar
                  user={{
                    ...craftworker.user,
                    avatarUrl: craftworker.avatarUrl,
                  }}
                />
                <div className="w-full">
                  <span
                    className={`flex items-center justify-between gap-2 truncate font-bold leading-none ${craftworker.selected && "group-hover:text-black"}`}
                  >
                    {craftworker.name}{" "}
                    {craftworker.needsApproval ? (
                      <ExclamationTriangleIcon
                        data-controller="tooltip"
                        data-tippy-content="Needs Approval"
                        className="h-4 w-4 text-yellow-500"
                      />
                    ) : (
                      ""
                    )}
                  </span>
                  <span
                    className={`flex text-sm tabular-nums text-gray-500 ${craftworker.selected && " group-hover:text-black"}`}
                  >
                    {`${craftworker.totalTimesheets} timesheet${craftworker.totalTimesheets === 1 ? "" : "s"} | ${craftworker.normalHours} hrs | ${craftworker.overtimeHours} overtime hrs`}
                  </span>
                </div>
              </button>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}
