import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="paint-color-select"
export default class extends Controller {
  static targets = ["colorId", "query", "options", "control"];

  async connect() {
    await this.runQuery();
  }

  async runQuery() {
    this.colors = await fetch(
      `/paint_colors.json?query=${this.queryTarget.value}`,
    ).then((r) => r.json());
    this.updateOptions();
  }

  select(event) {
    this.colorIdTarget.value = event.currentTarget.dataset.id;
    this.queryTarget.value = event.currentTarget.dataset.name;

    // When using in a modal, if we remove the option from the list on the same
    // tick, the modal will also close before we can submit the form
    setTimeout(() => {
      this.updateOptions();
      this.hideOptions();
    }, 1);
  }

  hideOptions(event) {
    this.optionsTarget.classList.add("hidden");
  }

  showOptions() {
    this.optionsTarget.classList.remove("hidden");
  }

  updateOptions() {
    this.optionsTarget.innerHTML = "";
    this.colors.forEach((color) => {
      const pre = `
        <li data-action="click->paint-color-select#select" data-name="${color.name}" data-id="${color.id}" class="relative cursor-pointer select-none py-2 pl-3 pr-9 text-gray-900 hover:bg-gray-50" role="option" tabindex="-1">
          <div class="flex gap-2 items-center">
            <div class="shrink-0 w-6 h-6 rounded border" style="background-color: ${color.hex}"></div>
            <div>
              <span class="flex-1 font-medium truncate">${color.name}</span>
              <div class="flex gap-1">
                <span class="truncate">${color.externalId}</span>
              </div>
            </div>
          </div>
      `;
      const active = `
        <span class="absolute inset-y-0 right-0 flex items-center pr-2 text-gray-600">
          <svg class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
          </svg>
        </span>
      `;
      const post = `</li>`;
      const content =
        pre + (color.id == this.colorIdTarget.value ? active : "") + post;

      this.optionsTarget.insertAdjacentHTML("beforeend", content);
    });
  }

  // hide modal when clicking ESC
  // action: "keyup@window->turbo-modal#closeWithKeyboard"
  closeWithKeyboard(e) {
    if (e.code == "Escape") {
      this.hideOptions();
    }
  }

  // hide modal when clicking outside of modal
  // action: "click@window->turbo-modal#closeBackground"
  closeBackground(e) {
    if (e && this.controlTarget.contains(e.target)) {
      return;
    }
    this.hideOptions();
  }
}
