import type { UseQueryOptions } from "@tanstack/react-query";

import { useApiQuery } from "@/api/openapi";
import type { GetParams, GetSuccessResponse } from "@/types/api/util";

export const useAccountManagers = (
  params?: GetParams<"/api/v2/craftworkers">,
  config?: Omit<
    UseQueryOptions<GetSuccessResponse<"/api/v2/craftworkers">>,
    "queryKey" | "queryFn"
  >,
) => {
  return useApiQuery(
    "/api/v2/craftworkers",
    {
      params: {
        role: ["account_manager"],
        limit: 100,
        kept: true,
        ...params,
      },
    },
    config,
  );
};
