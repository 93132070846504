export type AtLeast<T, K extends keyof T> = Partial<T> & Pick<T, K>;

export type Account = {
  [key: string]: any;
  id: string;
  name: string;
};

export type AccountOwner = {
  avatarUdl: string;
  email: string;
  firstName: string;
  id: string | number;
  lastName: string;
  name: string;
  phone: string;
  sgid: string;
};

export type Address = Partial<{
  line1: string;
  line2: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  addressType: string;
  lng: number;
  lat: number;
  placeId?: string;
}>;

export type ActiveStorageAttachment = {
  attachmentFileName: string;
  blobId: number;
  createdAt: string;
  discardedAt?: string | null;
  filename: string;
  height: number;
  id: string;
  name: string;
  railsUrl: string;
  recordId: number;
  recordType: string;
  type: string;
  url: string;
  width: number;
};

export type StaffingCounts = {
  assigned?: number;
  committed?: number;
  unplanned?: number;
};

export type CalendarEvent = {
  id: string;
  accountOwnerName: string;
  end: string;
  eventId: string | null;
  locationId: string;
  locationShortAddress: string;
  projectId: string | null;
  projectType: ProjectType;
  resourceId: string;
  resourceIds: string[];
  reworkReasonId?: string | null;
  reworkReasonTitle?: string | null;
  shifts: Shift[];
  staffing: StaffingCounts;
  start: string;
  title: string;
  url: string;
};

export type CalendarCraftworker = {
  id: number;
  department: string;
  experience: string;
  joinedDate: string;
  emoji: string | null;
  foodEmoji: string | null;
  foodName: string | null;
  homeCity: string | null;
  discardedAt: string | null;
  email: string;
  phone: string;
  name: string;
  firstName: string;
  lastName: string;
  familiarName: string;
  title: string;
  team: string | null;
  level: string;
  clocksIn: boolean;
  roles: string[];
  avatarUrl: string;
  languages: string[];
  isProductionLead: boolean;
};

// New type with nested user structure
export type Craftworker = {
  id: string;
  department: string;
  experience: string;
  joinedDate: string;
  emoji: string | null;
  foodEmoji: string | null;
  foodName: string | null;
  homeCity: string | null;
  discardedAt: string | null;
  userId: string;
  user: {
    email: string;
    phone: string;
    name: string;
    firstName: string;
    lastName: string;
    familiarName: string;
  };
  title: string;
  team: string | null;
  level: string;
  clocksIn: boolean;
  roles: string[]; //TO-DO Set roles to type def. below
  avatarUrl: string;
};

export const CraftworkerRoles = {
  painter: "painter",
  driver: "driver",
  paintLead: "paint_lead",
  productionLead: "production_lead",
  accountManager: "project_manager",
} as const;

export type CraftworkerRole =
  (typeof CraftworkerRoles)[keyof typeof CraftworkerRoles];

// Type guard to check which version we're dealing with
export function isNewCraftworker(
  craftworker: Craftworker,
): craftworker is Craftworker {
  return "user" in craftworker;
}

export type CraftworkersAvailability = Record<
  string,
  CraftworkersAvailabilityDay
>;

export type CraftworkersAvailabilityDay = {
  date: string;
  total: number;
  available: number;
  staffedPercentage: number;
  availableCraftworkerIds: {
    id: number;
  }[];
  availableCraftworkers?: CalendarCraftworker[];
  slots: number;
};

export type CraftworkerOpening = {
  end: string;
  craftworker: Craftworker;
  customerId: string | "no_customer";
  locationId: string | "no_location";
  pto: boolean;
  team: string;
  timeOff: boolean;
  timesheets: Timesheet[];
  totalShiftHouts: number;
  totalTimesheetHours: number;
  id: string;
  resourceIds: string[];
  start: string;
  title: string;
  url: string;
};

export type CustomView = {
  id: number;
  userId: number;
  name: string;
  description: string | null;
  icon: string;
  private: boolean;
  url: string;
  createdAt: string;
  updatedAt: string;
};

export type DailyPainterAvailability = {
  date: string;
  count: number;
};
export type EstimateStatus = "internalReview" | "internallyApproved";

export type EstimateRecord = {
  id: number;
  projectId: number;
  status: EstimateStatus;
  total: number;
  accountName: string;
  projectType: ProjectType;
};

export type Estimate = {
  id: number;
  projectId: number;
  status: EstimateStatus;
  total: number;
  accountName: string;
  projectType: ProjectType;
};

export type FullCalendarDateInfo = {
  end: Date;
  endStr: string;
  start: Date;
  startStr: string;
  timeZone: string;
  view: {
    type: string;
  };
};

export const projectTypeMap = {
  interior: "Interior",
  exterior: "Exterior",
  cabinets: "Cabinets",
  specialty: "Specialty",
} as const;

export enum ProjectType {
  Interior = "interior",
  Exterior = "exterior",
  Cabinets = "cabinets",
  Specialty = "specialty",
}

export type ProjectMedia = {
  id: number;
  name: string;
  key: string;
  filename: string;
  contentType: string;
  createdAt: string;
  byteSize: number;
  checksum: string;
  url: string;
};

export type ProjectSummary = {
  id: number;
  customTitle: string;
  title: string;
  salesStatus: string;
  projectStatus: string;
  projectType: ProjectType;
  sector: string;
  startDate: string | null;
  endDate: string | null;
  createdAt: string;
  updatedAt: string;
  leadScore: number | null;
  accountId: number;
  accountName: string;
  addressLine1: string | null;
  addressLine2: string | null;
  addressCity: string | null;
  addressState: string | null;
  addressPostalCode: string | null;
  addressLat: number | null;
  addressLng: number | null;
  interiorAreasCount: number;
  cabinetAreasCount: number;
  exteriorAreasCount: number;
  specialtyAreasCount: number;
  projectMedia: ProjectMedia[];
  locationMedia: ProjectMedia[];
};

export type Project = {
  title: string;
  project_type: string;
  start_date?: string | null;
  end_date?: string | null;
  location_id: string;
  project_paint_colors: any; // Type depends on the actual structure
  paint_colors: any; // Type depends on the actual structure
  project_status: string;
  approved_estimates: unknown;
  tasks: unknown;
  id: string;
  location?: Location | null;
  internal_project_id: string;
  dates?: string | null;
  date_range?: string | null;
  manager?: (User & { craftworker: Craftworker }) | null;
  account_owner?: User | null;
  preferences: Record<string, unknown>;
  address?: Address | null;
  crew_members: Record<string, unknown>;
};

export type ProjectCalendarEvent = {
  title: string;
  start: string | number;
  end: string | number;
  id: string;
  url: string;
  resourceId: string;
  extendedProps: {
    customTitle: string;
    projectId: number;
    projectType: ProjectType;
    eventType: string;
    status: string;
    taskCount: number;
    completedTaskCount: number;
    internalId: string;
    customerName: string;
    phoneNumber: string;
    address: Address;
    revenue: number;
    balance: number;
    potential: number;
    salesStatus: string;
    internalProjectId: string;
    crewMembers: Craftworker[];
    painters: Craftworker[];
    reworkReason?: string | null;
  };
};

export type Resource = {
  resourceId: string;
  resourceName?: string;
  account: Account;
  accountOwner: AccountOwner;
  address: string;
  adjustedStart: number;
  completedTaskCount: number;
  customTitle?: string | null;
  dates: string[];
  duration: number;
  fullTitle: string;
  hoursCommitted: number;
  hoursStaffed: number;
  hoursWorked: number;
  id: string;
  internalId: string;
  internalProjectId: string;
  locationId: string;
  projectType: ProjectType;
  resourceType: string | "Project";
  taskCount: number;
  title: string;
  totalEventDays: number;
  url: string;
  productionLeadId: number | null;
  projectId: string;
};

export type Shift = {
  craftworker?: CalendarCraftworker;
  craftworkerId: number;
  end: string;
  pto: boolean;
  published: boolean;
  shiftId: string;
  start: string;
  timeOff: boolean;
  timesheets: Timesheet[];
  unPto: boolean;
};

export type StaffingCalendarProps = {
  apiKey: string;
};

export type StaffingResource = {
  extendedProps: {
    craftworker: CalendarCraftworker;
  };
  start: string;
};

export type Timesheet = {
  approved: boolean;
  craftworkerId: number;
  craftworker?: CalendarCraftworker;
  endedAt: string;
  hours: number;
  inProgress: boolean;
  onBreak: boolean;
  startedAt: string;
  timesheetId: string;
};

export type FullCalendarToolbarOptions = Record<
  string,
  {
    left: string;
    center: string;
    right: string;
  }
>;

export type User = {
  id: string;
  name: string;
  email: string;
  phone: string;
  firstName: string;
  lastName: string;
  admin?: boolean | null;
  avatarUrl?: string | null;
};

export type WeatherReport = {
  code: string;
  createdAt: string;
  day: string;
  description: string;
  icon: ActiveStorageAttachment;
  id: string;
  marketId: number;
  tempMax: number; // integer
  tempMin: number; // integer
  temperatureMax: number;
  temperatureMin: number;
  updatedAt: string;
};
