import React from "react";
import {
  ChevronExpandY,
  CircleLogout,
  Gear,
  UserList,
  UserSettings,
} from "@craftworkco/nucleo-icons";

import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/Avatar";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/DropdownMenu";
import {
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  useSidebar,
} from "@/components/ui/Sidebar";

type UserMenuProps = {
  avatarUrl: string;
  userName: string;
  admin: boolean;
  collapsed: boolean;
  engOnly: boolean;
  logoutUrl: string;
};

function UserMenu({
  avatarUrl,
  userName,
  admin,
  engOnly,
  logoutUrl,
}: UserMenuProps) {
  const { isMobile } = useSidebar();

  return (
    <SidebarMenu>
      <SidebarMenuItem>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <SidebarMenuButton
              size="lg"
              className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
            >
              <div className="flex items-center gap-2 text-left text-sm">
                <Avatar className="h-8 w-8 rounded-lg">
                  <AvatarImage src={avatarUrl} alt={userName} />
                  <AvatarFallback className="rounded-lg">
                    {userName.charAt(0)}
                  </AvatarFallback>
                </Avatar>
                <div className="grid flex-1 text-left text-sm leading-tight">
                  <span className="truncate font-semibold">{userName}</span>
                  {admin && (
                    <span className="text-muted-foreground truncate text-xs">
                      Admin
                    </span>
                  )}
                </div>
              </div>
              <ChevronExpandY className="ml-auto size-4" />
            </SidebarMenuButton>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            className="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg bg-white"
            side={isMobile ? "bottom" : "right"}
            align="end"
            sideOffset={4}
          >
            <DropdownMenuGroup>
              <DropdownMenuItem>
                <a
                  href="/users/edit"
                  className="flex w-full items-center gap-2"
                >
                  <UserSettings />
                  Profile
                </a>
              </DropdownMenuItem>
              <DropdownMenuItem>
                <a href="/accounts" className="flex w-full items-center gap-2">
                  <UserList />
                  Accounts
                </a>
              </DropdownMenuItem>
              {engOnly && (
                <DropdownMenuItem>
                  <a href="/admin" className="flex w-full items-center gap-2">
                    <Gear />
                    Admin
                  </a>
                </DropdownMenuItem>
              )}
            </DropdownMenuGroup>
            <DropdownMenuSeparator className="bg-gray-300" />
            <DropdownMenuItem>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  fetch(logoutUrl, {
                    method: "DELETE",
                    headers: {
                      "X-CSRF-Token":
                        document
                          .querySelector('meta[name="csrf-token"]')
                          ?.getAttribute("content") || "",
                    },
                  }).then((response) => {
                    if (response.ok) {
                      window.location.href = "/"; // Redirect after logout
                    }
                  });
                }}
                className="flex w-full items-center gap-2"
              >
                <CircleLogout />
                Log out
              </a>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </SidebarMenuItem>
    </SidebarMenu>
  );
}

export default UserMenu;
