import React from "react";
import { Menu } from "@craftworkco/nucleo-icons";

import type { CustomView } from "@/types";

import AppSidebar from "./ui/nav/AppSidebar";
import { SidebarProvider, SidebarTrigger } from "./ui/Sidebar";

type NavbarProps = {
  currentUser: number;
  favorites: CustomView[];
  engOnly: boolean;
  avatarUrl: string;
  userName: string;
  admin: boolean;
  defaultOpen: boolean;
  initialPath: string;
  unreadCount: number;
  logoutUrl: string;
};

export const Navbar: React.FC<NavbarProps> = ({
  favorites,
  avatarUrl,
  engOnly,
  userName,
  admin,
  defaultOpen,
  initialPath,
  unreadCount,
  logoutUrl,
}) => {
  return (
    <SidebarProvider defaultOpen={defaultOpen}>
      <SidebarTrigger className="absolute left-2 top-2 z-30 md:hidden">
        <Menu className="h-5 w-5" />
      </SidebarTrigger>
      <AppSidebar
        avatarUrl={avatarUrl}
        userName={userName}
        admin={admin}
        favorites={favorites}
        engOnly={engOnly}
        initialPath={initialPath}
        unreadCount={unreadCount}
        logoutUrl={logoutUrl}
      />
    </SidebarProvider>
  );
};
