import React from "react";

type LogoProps = {
  size?: "sm" | "md" | "lg";
  light?: boolean;
};

const aspectRatio = 176 / 32;

const logoWidth = {
  sm: 150,
  md: 176,
  lg: 320,
};

const logoHeight = {
  sm: logoWidth.sm / aspectRatio,
  md: logoWidth.md / aspectRatio,
  lg: logoWidth.lg / aspectRatio,
};

export const Logo: React.FC<LogoProps> = ({ size = "md", light = false }) => {
  const width = logoWidth[size];
  const height = logoHeight[size];
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 667 106"
      fill={light ? "white" : "black"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M105.862 76.2512H30.5766V50.9085L59.2385 34.3547L105.862 60.174V25.8408L59.1947 0L0 34.176V105.862H105.862V76.2512Z"
      />
      <path d="M406.871 102.157V90.801C405.945 91.299 405.008 91.7127 404.056 92.0523C402.681 92.543 400.988 92.7677 399.031 92.7677H398.742L398.725 92.7512C396.062 92.7017 393.936 91.9684 392.47 90.4426C390.956 88.8666 390.26 86.5646 390.26 83.6687V62.0002H406.483V49.47H390.26V30.322L374.36 34.5567V85.9127C374.36 89.9146 375.105 93.382 376.583 96.3224L376.584 96.3236C378.057 99.2663 380.227 101.531 383.11 103.157C385.986 104.766 389.53 105.601 393.771 105.601C396.828 105.601 399.343 105.288 401.334 104.695C403.231 104.116 405.073 103.275 406.871 102.157Z" />
      <path d="M365.215 61.9727H349.592V103.646H333.692V61.9727H323.906V49.4424H333.692V44.4894C333.692 37.618 335.499 32.3892 339.034 28.7018C342.556 25.028 347.799 23.1355 354.889 23.1355C357.362 23.1355 359.635 23.3788 361.71 23.8477C363.593 24.2762 365.105 24.8308 366.244 25.4766V36.7836C364.202 35.8503 361.741 35.4041 358.889 35.4041C355.801 35.4041 353.407 36.0951 351.848 37.6457C350.298 39.187 349.592 41.4354 349.592 44.2691V49.4424H365.215V61.9727Z" />
      <path d="M194.801 104.084C198.919 102.942 202.537 101.36 205.637 99.356V84.6615C202.977 86.3938 200.011 87.81 196.763 88.9126L196.763 88.9171C193.036 90.181 189.181 90.8128 185.216 90.8128C179.821 90.8128 175.069 89.7476 170.993 87.6073C166.922 85.4697 163.777 82.4874 161.576 78.6777L161.575 78.676C159.389 74.8679 158.298 70.4771 158.298 65.6337C158.298 60.7904 159.375 56.4808 161.576 52.6987C163.764 48.9155 166.897 45.9747 170.94 43.8791C174.988 41.7666 179.699 40.7299 185.05 40.7299C189.154 40.7299 193.014 41.3895 196.61 42.6851L196.611 42.6854C199.723 43.8111 202.593 45.2698 205.208 47.0628V31.7806C202.245 30.0479 198.829 28.6101 194.932 27.4838C190.907 26.3206 186.474 25.7354 181.631 25.7354C173.441 25.7354 166.296 27.4751 160.159 30.9186C154.016 34.3654 149.28 39.144 145.939 45.2638C142.596 51.3858 140.917 58.358 140.917 66.1706C140.917 73.5958 142.526 80.2971 145.708 86.329C148.883 92.3472 153.521 97.098 159.625 100.6C165.729 104.108 172.981 105.862 181.423 105.862C186.083 105.862 190.546 105.264 194.801 104.084Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M269.01 51.7007C265.216 54.3105 262.306 57.8035 260.28 62.1964C258.249 66.6009 257.23 71.2359 257.23 76.6612C257.23 82.0987 258.184 86.9845 260.091 91.3306L260.092 91.3322C261.982 95.6689 264.726 99.0745 268.309 101.584C271.874 104.066 276.09 105.325 281.015 105.325C285.189 105.325 288.789 104.323 291.83 102.342L291.832 102.341C294.913 100.342 297.22 97.7664 298.781 94.5804H300.112V103.66H315.735V49.4562H299.835V57.5686H298.51C296.874 54.3682 294.676 51.9515 291.933 50.291C289.19 48.6304 286.01 47.7904 282.372 47.7904C277.239 47.7904 272.799 49.1046 269.01 51.7007ZM298.542 68.4312L298.543 68.4344C299.585 70.7947 300.112 73.4955 300.112 76.5097C300.112 79.5263 299.571 82.2309 298.484 84.5948C297.394 86.9638 295.815 88.832 293.772 90.1623C291.723 91.4968 289.289 92.162 286.718 92.162C284.15 92.162 281.833 91.5399 279.801 90.2808C277.767 89.0197 276.197 87.2179 275.106 84.8732C274.017 82.548 273.476 79.9104 273.476 76.7851C273.476 73.6621 274.016 70.8869 275.133 68.4777C276.248 66.072 277.826 64.1891 279.871 62.8572C281.919 61.5238 284.269 60.8575 286.926 60.8575C289.582 60.8575 291.92 61.5238 293.942 62.8597L293.944 62.8611C295.947 64.1939 297.483 66.064 298.542 68.4312Z"
      />
      <path d="M472.775 86.4559L462.448 49.4562H446.099L435.797 86.1272L426.638 49.4562H410.813L426.303 103.66H442.334L453.712 65.9904L465.38 103.66H481.759L498.517 49.4562H482.867L472.775 86.4559Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M545.88 51.3826C550.102 53.6539 553.439 56.7955 555.91 60.8386L556.317 61.5487C558.823 65.931 560.086 70.9982 560.086 76.7713C560.086 82.5397 558.811 87.411 556.267 91.7095L556.266 91.7103C553.716 96.0307 550.213 99.3685 545.758 101.745C541.286 104.124 536.266 105.325 530.681 105.325C525.096 105.325 520.146 104.137 515.719 101.747L515.717 101.747C511.29 99.3704 507.814 96.0332 505.292 91.7131L505.29 91.7109C502.76 87.4137 501.498 82.4322 501.498 76.7713C501.498 71.1058 502.748 65.9284 505.24 61.5473C507.736 57.1594 511.184 53.7836 515.623 51.3817C520.05 48.992 525.069 47.7766 530.695 47.7766C536.323 47.7766 541.411 48.9793 545.88 51.3826ZM537.695 89.3535L537.695 90.203C535.695 91.514 533.328 92.1482 530.681 92.1482C528.031 92.1482 525.718 91.5126 523.745 90.1997C521.782 88.8931 520.288 87.0629 519.244 84.7104L519.243 84.7086C518.215 82.3746 517.702 79.7562 517.702 76.6612C517.702 73.5748 518.184 70.8453 519.214 68.479C520.243 66.1138 521.736 64.2409 523.714 62.905C525.697 61.5651 528.011 60.8988 530.681 60.8988C533.348 60.8988 535.717 61.5636 537.716 62.9037C539.723 64.2401 541.229 66.1275 542.272 68.5047L542.273 68.508C543.221 70.6991 543.73 73.1905 543.803 75.9601H543.826V76.6612C543.826 79.6727 543.314 82.3625 542.255 84.7137C541.196 87.066 539.674 88.9104 537.695 90.203L537.696 90.2024L537.696 89.3528L537.695 89.3535Z"
      />
      <path d="M643.414 73.3238L664.724 49.4424H645.976L627.856 72.2555V25.7354H612.066V103.66H627.856V75.5812L647.676 103.66H666.014L643.414 73.8405V73.3238Z" />
      <path d="M253.089 63.041H232.243V103.646H216.633V49.6631H253.089V63.041Z" />
      <path d="M584.183 63.041H605.029V49.6631H568.573V103.646H584.183V63.041Z" />
    </svg>
  );
};

const submarkSize = {
  sm: 20,
  md: 24,
  lg: 32,
};

export const Submark: React.FC<LogoProps> = ({
  size = "md",
  light = false,
}) => {
  const width = submarkSize[size];
  return (
    <svg
      viewBox="0 0 270 270"
      fill={light ? "white" : "black"}
      width={width}
      height={width}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M270 194.477H77.985V129.841L151.087 87.6211L270 153.473V65.9064L150.975 0L0 87.1652V270H270V194.477Z"
      />
    </svg>
  );
};
