import React, { useCallback, useState } from "react";

import { Popover } from "@/components/ui/Popover";
import Button from "@/ui/Button";

type MenuItem = {
  label: string;
  href?: string;
  component?: React.ReactNode;
  onClick?: () => void;
  children?: MenuItem[];
  active?: boolean;
  divider?: boolean;
  "data-turbo-frame"?: string;
};

const ChevronIcon: React.FC<{
  direction: "down" | "right";
  isOpen: boolean;
}> = ({ direction, isOpen }) => (
  <svg
    className={`ms-3 h-2.5 w-2.5 transition-transform duration-200 ${isOpen ? "rotate-180" : ""}`}
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox={direction === "down" ? "0 0 10 6" : "0 0 6 10"}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d={direction === "down" ? "m1 1 4 4 4-4" : "m1 9 4-4-4-4"}
    />
  </svg>
);

type MenuItemComponentProps = {
  item: MenuItem;
  depth: number;
  closeMenu: () => void;
};

const MenuItemComponent: React.FC<MenuItemComponentProps> = ({
  item,
  depth,
  closeMenu,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const activeClass = item.active ? "bg-gray-100 text-gray-900" : "";
  const baseClass =
    "px-4 py-2 rounded-none dark:hover:bg-gray-600 w-full justify-between";

  const handleItemClick = useCallback(() => {
    if (item.onClick) {
      item.onClick();
    }
    if (!item.children) {
      closeMenu();
    }
  }, [item, closeMenu]);

  if (item.divider) {
    return <li className="my-2 h-px bg-gray-200" />;
  }

  if (item.children) {
    return (
      <li>
        <Popover
          trigger="click"
          placement="right-start"
          arrow={false}
          content={
            <div className="z-10 w-44 divide-y divide-gray-100 rounded-lg bg-white shadow dark:bg-gray-700">
              <ul
                className="py-2 text-sm text-gray-700 dark:text-gray-200"
                aria-labelledby={`dropdownButton-${item.label}`}
              >
                {item.children.map((child, index) => (
                  <MenuItemComponent
                    key={index}
                    item={child}
                    depth={depth + 1}
                    closeMenu={closeMenu}
                  />
                ))}
              </ul>
            </div>
          }
        >
          <Button
            id={`dropdownButton-${item.label}`}
            variant="ghost"
            type="button"
            onClick={() => setIsOpen(!isOpen)}
            className={`flex w-full items-center justify-between ${baseClass} ${activeClass}`}
          >
            {item.label}
            <ChevronIcon direction="right" isOpen={isOpen} />
          </Button>
        </Popover>
      </li>
    );
  }

  if (item.href) {
    return (
      <li>
        <Button
          href={item.href}
          className={`${baseClass} ${activeClass}`}
          variant="ghost"
          data-turbo-frame={item["data-turbo-frame"]}
        >
          {item.label}
        </Button>
      </li>
    );
  }

  if (item.component) {
    return <li className="px-4 py-2">{item.component}</li>;
  }

  return (
    <li>
      <Button
        onClick={handleItemClick}
        variant="ghost"
        className={`w-full text-left ${baseClass} ${activeClass}`}
        data-turbo-frame={item["data-turbo-frame"]}
      >
        {item.label}
      </Button>
    </li>
  );
};

type NestedMenuProps = {
  items: MenuItem[];
  title: string;
  icon?: React.ReactNode;
};

export default function NestedMenu({ items, title, icon }: NestedMenuProps) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <Popover
      trigger="click"
      placement="bottom"
      arrow={false}
      open={isMenuOpen}
      onOpenChange={setIsMenuOpen}
      content={
        <div
          id="multi-dropdown"
          className="z-10 divide-y divide-gray-100 rounded-lg bg-white shadow dark:bg-gray-700"
        >
          <ul
            className="py-2 text-sm text-gray-700 dark:text-gray-200"
            aria-labelledby="multiLevelDropdownButton"
          >
            {items.map((item, index) => (
              <MenuItemComponent
                key={index}
                item={item}
                depth={0}
                closeMenu={() => setIsMenuOpen(false)}
              />
            ))}
          </ul>
        </div>
      }
    >
      <Button
        id="multiLevelDropdownButton"
        className=" inline-flex items-center rounded-lg px-5 py-2.5 text-center font-medium text-sm"
        variant="secondary"
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      >
        {icon}
        {title}
        {!icon && <ChevronIcon direction="down" isOpen={isMenuOpen} />}
      </Button>
    </Popover>
  );
}
