import React from "react";

import { useApiQuery } from "@/api/openapi";
import { useAccountManagers } from "@/hooks/useAccountManagers";

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectItemWithAvatar,
  SelectTrigger,
  SelectValue,
} from "../ui/SelectDropdown";

type AccountManagerSelectProps = {
  selected?: string;
  onSelect: (value: string) => void;
};

export const AccountManagerSelect = ({
  selected,
  onSelect,
}: AccountManagerSelectProps) => {
  const { data } = useAccountManagers();
  const accountManagers = data?.data;

  const selectedAccountManager = accountManagers?.find(
    (manager) => manager.userPrefixId === selected,
  );

  return (
    <Select
      onValueChange={onSelect}
      value={selectedAccountManager?.userPrefixId}
    >
      <SelectTrigger className="w-full">
        <SelectValue placeholder="All Account Managers" />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectItem value="all">All Account Managers</SelectItem>
        </SelectGroup>
        <SelectGroup>
          {accountManagers?.map((manager) => (
            <SelectItemWithAvatar
              key={manager.userPrefixId}
              value={manager.userPrefixId}
              avatarUrl={manager.avatarUrl ?? undefined}
              className="flex w-full items-center"
            >
              {manager.firstName} {manager.lastName}
            </SelectItemWithAvatar>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};
