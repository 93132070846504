import React from "react";
import { FileAlert, Photo } from "@craftworkco/nucleo-icons";

import useProjectMedia from "@/hooks/useProjectMedia";

import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "./Carousel";
import { Skeleton } from "./Skeleton";

const NoImage = () => (
  <div className="flex h-full w-full flex-col items-center justify-center bg-gray-100 text-sm text-gray-500">
    <Photo size={32} />
    <p className="font-bold">No Images</p>
  </div>
);

const UnsupportedMediaType = () => (
  <div className="flex h-full w-full flex-col items-center justify-center bg-gray-100 text-sm text-gray-500">
    <FileAlert size={32} />
    <p className="font-bold">Unsupported Media Type</p>
  </div>
);

export const ProjectMediaGallerySlider = ({
  projectId,
}: {
  projectId?: string;
}) => {
  const { data: media, isLoading } = useProjectMedia(projectId);

  return (
    <div className="relative h-64 w-full overflow-hidden">
      {isLoading ? (
        <Skeleton className="h-full w-full" />
      ) : (
        <Carousel className="h-full w-full">
          <CarouselContent>
            {media && media.length > 0 ? (
              media
                .filter((item) => item.name !== "header")
                .map((item, index) => {
                  if (item.contentType.includes("image")) {
                    return (
                      <CarouselItem key={index}>
                        <img
                          src={item.url}
                          alt={item.name}
                          className="h-full w-full object-cover"
                        />
                      </CarouselItem>
                    );
                  }
                  return (
                    <CarouselItem key={index}>
                      <UnsupportedMediaType />
                    </CarouselItem>
                  );
                })
            ) : (
              <CarouselItem>
                <NoImage />
              </CarouselItem>
            )}
          </CarouselContent>
          <CarouselPrevious />
          <CarouselNext />
        </Carousel>
      )}
    </div>
  );
};
