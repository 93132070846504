import React from "react";
import { FunnelIcon } from "@heroicons/react/24/outline";

import { Filter, FilterPill } from "@/ui/FilterPill";
import { Option } from "@/ui/Select";
import { clsxm } from "@/utils/clsxm";

type TableFiltersProps = {
  filters: Filter[];
  table: string;
  alwaysShowQuickFilter?: boolean;
};

export const TableFilters: React.FC<TableFiltersProps> = ({
  filters,
  table,
}) => {
  const [showAddFilter, setShowAddFilter] = React.useState(false);
  const [filterMenuLeft, setFilterMenuLeft] = React.useState(false);
  const menuRef = React.useRef<HTMLUListElement>(null);
  const filterParams = new URLSearchParams(window.location.search);
  const sessionFilters = localStorage.getItem(table) || null;
  const hasFilters = filterParams.toString() !== "";

  if (!hasFilters && sessionFilters) {
    window.location.search = sessionFilters;
  }

  const selectedFilters = filters.filter((item) => {
    if (filterParams.get(item.name) === null) return;
    return item;
  });

  const updateFilters = (filter: string, option: Option | null) => {
    filterParams.set(filter, option?.value.toString() || "");
    window.location.search = filterParams.toString();
    localStorage.setItem(table, filterParams.toString());
  };

  const handleAddFilter = (filter: string, option: Option | null) => {
    const newFilter = filters.find((f) => f.name === filter);
    if (!newFilter) return;
    updateFilters(filter, option);
  };

  const handleRemoveFilter = (filter: string) => {
    filterParams.delete(filter);
    window.location.search = filterParams.toString();
    localStorage.setItem(table, filterParams.toString());
  };

  const handleClearFilters = () => {
    // clear all queries that don't begin with display_properties
    const filterKeys = filterParams.toString().split("&");
    for (const key of filterKeys) {
      if (!key.startsWith("display_properties")) {
        const keyName = key.split("=")?.[0];
        if (keyName) {
          filterParams.delete(keyName);
        }
      }
    }
    window.location.search = filterParams.toString();
    localStorage.removeItem(table);
  };

  React.useEffect(() => {
    if (!menuRef.current) return;
    // This prevents the menu from being cut off on the right side of the screen and the left
    const menuRect = menuRef.current.getBoundingClientRect();
    const menuWidth = menuRect.width;
    const freeSpaceRight = window.innerWidth - menuRect.right;

    if (menuWidth > freeSpaceRight) {
      setFilterMenuLeft(false);
    } else {
      setFilterMenuLeft(true);
    }
  }, [menuRef.current]);

  return (
    <div className="flex w-full flex-row-reverse items-center justify-end gap-1 sm:w-auto sm:flex-row">
      {selectedFilters.length > 0 && (
        <div className="flex gap-2">
          {selectedFilters.map((filter) => (
            <FilterPill
              key={filter.name}
              initialValue={filterParams.get(filter.name)}
              filter={filter}
              updateFilterOption={(filter, option) =>
                updateFilters(filter, option)
              }
              removeFilter={handleRemoveFilter}
            />
          ))}
          {selectedFilters.length > 1 && (
            // button to clear all filters
            <button
              type="button"
              className="flex h-6 items-center rounded-full bg-gray-50 px-2 text-xs focus-within:ring-2 focus-within:ring-offset-1 hover:bg-alert/50 hover:text-white"
              onClick={handleClearFilters}
            >
              Clear all
            </button>
          )}
        </div>
      )}
      <button
        type="button"
        onBlur={() => setShowAddFilter(false)}
        className="hover:bg-gray-25 relative flex h-8 w-8 items-center justify-center rounded-full text-xs text-gray-300 hover:text-gray-800"
        onClick={() => setShowAddFilter((prev) => !prev)}
      >
        <span className="sr-only">Filter</span>
        <FunnelIcon className="h-[20px] w-[20px]" />
        <ul
          ref={menuRef}
          className={clsxm(
            showAddFilter ? "visible" : "invisible",
            filterMenuLeft ? "left-0" : "right-0",
            "absolute top-full z-40 w-max rounded bg-white p-1 text-sm font-normal shadow-md",
          )}
        >
          {filters.map((filter, idx) => (
            <li
              className="w-full rounded px-4 py-2 text-left text-gray-900 hover:bg-gray-100"
              key={idx}
              onClick={() => handleAddFilter(filter.name, null)}
            >
              {filter.label}
            </li>
          ))}
        </ul>
      </button>
    </div>
  );
};
