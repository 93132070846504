import React from "react";

import { CraftworkerSelect } from "@/components/craftworkers/CraftworkerSelect";
import useRouter from "@/hooks/useRouter";
import { CraftworkerRoles } from "@/types";
import type { CalendarCraftworker, Resource } from "@/types";
import { clsxm } from "@/utils/clsxm";

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../ui/Tooltip";

type ProjectResourceLabelProps = {
  resource: Resource;
  productionLeads: CalendarCraftworker[];
};

export const ResourceLabel: React.FC<ProjectResourceLabelProps> = ({
  resource,
  productionLeads,
}) => {
  const {
    resourceName,
    url,
    projectType,
    totalEventDays,
    resourceType,
    customTitle,
    productionLeadId,
    resourceId,
    projectId,
  } = resource;
  const { params } = useRouter();
  const { style } = params;
  // Determine the color indicator based on the project type
  const indicatorColor = projectType ? `bg-${projectType}` : `bg-gray-200`;
  const selectedLead = productionLeads.find(
    (lead) => lead.id === productionLeadId,
  );

  const tooltipContent = customTitle
    ? `${resourceName} - ${customTitle}`
    : resourceName;

  return (
    <div
      className={clsxm(
        "sticky left-0 z-10 flex h-full w-full flex-row items-center justify-start gap-1 border-b border-r p-2",
      )}
    >
      <div
        className={`${style === "compact" ? "h-10" : "h-14"} mr-0.5 w-[2.5px] rounded ${indicatorColor}`}
      />
      <div className="flex-1 items-center justify-start truncate">
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <a href={url}>
                <span
                  className={clsxm(
                    "font-sm font-bold",
                    style === "compact" && "text-sm",
                  )}
                >
                  {resourceName}
                </span>
                {resourceType === "Project" &&
                  style !== "compact" &&
                  customTitle && (
                    <span className="font-regular text-sm text-gray-600">
                      {" "}
                      - {customTitle}
                    </span>
                  )}
              </a>
            </TooltipTrigger>
            <TooltipContent>{tooltipContent}</TooltipContent>
          </Tooltip>
        </TooltipProvider>

        <div
          className={clsxm(
            "mt-0.5 flex flex-row items-end justify-between",
            style === "compact" && "mt-0 items-center",
          )}
        >
          {resourceType === "Project" && (
            <>
              <CraftworkerSelect
                setRole={CraftworkerRoles.productionLead}
                projectId={projectId}
                resourceId={resourceId}
                selected={selectedLead}
                craftworkerOptions={productionLeads}
                compact={style === "compact"}
              />
              <p className="text-xs text-gray-600">{`${totalEventDays} day${totalEventDays !== 1 ? "s" : ""}`}</p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
