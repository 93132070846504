import React from "react";

import { AccountManagerSelect } from "@/components/craftworkers/AccountManagerSelect";
import { EstimateShow } from "@/components/estimates/EstimateShow";
import {
  EstimatesList,
  EstimatesListSkeleton,
} from "@/components/estimates/EstimatesList";
import {
  Main,
  Sidebar,
  SidebarLayout,
} from "@/components/layouts/SidebarLayout";
import { useEstimatesQuery } from "@/hooks/useEstimatesQuery";
import useRouter from "@/hooks/useRouter";
import { RootProvider } from "@/providers/RootProvider";

const ListFilters = () => {
  const { updateParam, params } = useRouter();
  const selectedManagerId = params.managerId;

  return (
    <div className="flex justify-between p-2">
      <AccountManagerSelect
        onSelect={(value) =>
          updateParam("managerId", value === "all" ? null : value)
        }
        selected={selectedManagerId}
      />
    </div>
  );
};

const List = () => {
  const { params } = useRouter();
  const managerId = params.managerId;

  const { data: estimates, isLoading } = useEstimatesQuery({
    status: ["internalReview", "internallyApproved"],
    managerId: managerId || undefined,
  });

  return (
    <Sidebar className="divide-y">
      <ListFilters />
      {estimates ? (
        <EstimatesList estimates={estimates} isLoading={isLoading} />
      ) : (
        <EstimatesListSkeleton />
      )}
    </Sidebar>
  );
};

const Show = () => {
  const { params } = useRouter();
  const estimateId = params.estimateId || "";

  return (
    <Main>
      {estimateId ? (
        <EstimateShow estimateId={estimateId} />
      ) : (
        <div>Select an Estimate</div>
      )}
    </Main>
  );
};

export const SalesEstimatesReviewIndex = () => {
  return (
    <RootProvider>
      <SidebarLayout sidebar={<List />} main={<Show />} />
    </RootProvider>
  );
};
