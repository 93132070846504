import React from "react";

import { EstimateRecord } from "@/types";
import { clsxm } from "@/utils/clsxm";
import { formatAsCurrency } from "@/utils/formatAsCurrency";

import ProjectTypeBadge from "../ProjectTypeBadge";

type EstimateListItemProps = {
  estimate: EstimateRecord;
  onSelect: (id: string) => void;
  isSelected: boolean;
};

export const EstimateListItem: React.FC<EstimateListItemProps> = ({
  estimate,
  onSelect,
  isSelected,
}) => {
  const handleEstimateRecordSelect = () => {
    onSelect(estimate.id.toString());
  };

  const { projectType, accountName, total } = estimate;

  return (
    <div className="group flex w-full items-center py-1 pl-1 pr-1 text-sm">
      <button
        onClick={handleEstimateRecordSelect}
        className={clsxm(
          "flex flex-1 items-center gap-2 overflow-hidden rounded px-2 py-1.5 text-left font-medium hover:bg-gray-50",
          isSelected && "bg-gray-50",
        )}
      >
        <ProjectTypeBadge projectType={projectType} />
        <span className="flex-1 truncate">{accountName}</span>
        <span className="text-right text-gray-500">
          {formatAsCurrency(total)}
        </span>
      </button>
      {/* <ContextMenu menu={contextMenu} /> */}
    </div>
  );
};

export const EstimateListItemSkeleton = () => {
  return <div className="h-10 w-full animate-pulse rounded-lg bg-gray-100" />;
};
