import React, { useState } from "react";
import {
  BellSnooze,
  CalendarCheck,
  CircleCheck,
  CircleCheckPlus,
  DotsVertical,
  UserPlus,
} from "@craftworkco/nucleo-icons";
import { add, format } from "date-fns";
import { toast } from "sonner";

import { useApiMutation } from "@/api/openapi";
import { queryClient } from "@/application";
import { useAccountManagers } from "@/hooks/useAccountManagers";
import useRouter from "@/hooks/useRouter";
import type { ProjectType } from "@/types";
import { projectTypeMap } from "@/types";
import type { GetSuccessResponse } from "@/types/api/util";
import { clsxm } from "@/utils/clsxm";

import CraftworkerAvatar from "../craftworkers/CraftworkerAvatar";
import ProjectTypeBadge from "../ProjectTypeBadge";
import { Calendar } from "../ui/Calendar";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "../ui/DropdownMenu";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/Popover";
import { Tooltip, TooltipContent, TooltipTrigger } from "../ui/Tooltip";

type CheckinListItemProps = {
  checkin: GetSuccessResponse<"/api/v2/tasks/{id}">;
};

export const CheckinListItem: React.FC<CheckinListItemProps> = ({
  checkin,
}) => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const { params, updateParamTurbo } = useRouter();
  const { accountName, projectType, projectPrefixId } = checkin;
  const isSelected = params.projectId === projectPrefixId;

  const { data: managerData } = useAccountManagers();
  const managers = managerData?.data;

  const { mutate: createTask } = useApiMutation(
    "/api/v2/tasks",
    undefined,
    "post",
    {
      onSuccess: (_data, variables) => {
        queryClient.invalidateQueries({ queryKey: ["/api/v2/tasks"] });
        toast.success(
          `${variables.name} created for ${format(variables.dueAt, "MMM, d")}`,
          {
            closeButton: true,
            duration: 3000,
          },
        );
      },
      onError: (error) => {
        const messages =
          "errors" in error && Array.isArray(error.errors)
            ? error.errors.join("\n")
            : error.message;
        toast.error("Failed to create checkin", {
          description: messages,
          duration: Infinity,
          closeButton: true,
        });
      },
    },
  );

  const { mutate: updateTask } = useApiMutation(
    "/api/v2/tasks/{id}",
    checkin.prefixId,
    "put",
    {
      onSuccess: (_data, variables) => {
        let message = accountName;
        if (variables.dueAt) {
          message += ` checkin due on ${format(new Date(variables.dueAt), "MMM, d")}`;
        }
        if (variables.completedAt) {
          message += " marked as complete";
        }
        if (variables.assigneePrefixId) {
          message += `, assigned to ${managers?.find((manager) => manager.userPrefixId === variables.assigneePrefixId)?.firstName}`;
        }
        toast.success(message, {
          duration: 5000,
          closeButton: true,
          action: {
            label: "Undo",
            onClick: () => {
              updateTask(checkin);
            },
          },
          onAutoClose: () => {
            if (variables.completedAt && params.projectId === projectPrefixId) {
              updateParamTurbo("projectId", "");
            }
          },
        });
      },
      onError: (error) => {
        const messages =
          "errors" in error && Array.isArray(error.errors)
            ? error.errors.join("\n")
            : error.message;
        toast.error("Failed to update checkin", {
          description: messages,
          duration: Infinity,
          closeButton: true,
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: ["/api/v2/tasks"] });
      },
    },
  );

  const validProjectType =
    projectType && Object.keys(projectTypeMap).includes(projectType)
      ? (projectType as ProjectType)
      : null;

  return (
    <div className="group flex w-full items-center py-1 pl-1 pr-1 text-sm ">
      <div
        className={clsxm(
          "flex w-full items-center rounded group-hover:bg-gray-50",
          isSelected && "bg-gray-50",
        )}
      >
        <button
          onClick={() => updateParamTurbo("projectId", projectPrefixId ?? "")}
          className="flex flex-1 items-center gap-2 overflow-hidden px-2 py-1.5 text-left font-base-medium"
        >
          <ProjectTypeBadge projectType={validProjectType} />
          <span className="flex-1 truncate">{accountName}</span>
        </button>

        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <button className="mr-2 rounded-full bg-gray-50 p-1 hover:bg-plum hover:text-white">
              <DotsVertical />
            </button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuItem
              onClick={() =>
                updateTask({
                  completedAt: new Date().toISOString(),
                })
              }
            >
              <CircleCheck />
              Mark as complete
            </DropdownMenuItem>
            <DropdownMenuSub>
              <DropdownMenuSubTrigger>
                <BellSnooze />
                Snooze
              </DropdownMenuSubTrigger>
              <DropdownMenuPortal>
                <DropdownMenuSubContent>
                  <DropdownMenuItem
                    onClick={() => {
                      updateTask({
                        dueAt: add(new Date(), { days: 1 }).toISOString(),
                      });
                    }}
                  >
                    Tomorrow
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    onClick={() => {
                      updateTask({
                        dueAt: add(new Date(), { days: 7 }).toISOString(),
                      });
                    }}
                  >
                    Next Week
                  </DropdownMenuItem>
                  <DropdownMenuSub>
                    <DropdownMenuSubTrigger>Set Date</DropdownMenuSubTrigger>
                    <DropdownMenuPortal>
                      <DropdownMenuSubContent className="p-0">
                        <Calendar
                          mode="single"
                          className="rounded-md border-none"
                          onSelect={(date) => {
                            if (!date) return;
                            updateTask({
                              dueAt: date.toISOString(),
                            });
                          }}
                        />
                      </DropdownMenuSubContent>
                    </DropdownMenuPortal>
                  </DropdownMenuSub>
                </DropdownMenuSubContent>
              </DropdownMenuPortal>
            </DropdownMenuSub>
            <DropdownMenuSub>
              <DropdownMenuSubTrigger>
                <CalendarCheck />
                Complete and Follow-up
              </DropdownMenuSubTrigger>
              <DropdownMenuPortal>
                <DropdownMenuSubContent className="p-0">
                  <Calendar
                    mode="single"
                    className="rounded-md border-none"
                    onSelect={(date) => {
                      if (!date) return;
                      updateTask({
                        completedAt: new Date().toISOString(),
                      });
                      createTask({
                        taskType: "check_in",
                        dueAt: date.toISOString(),
                        name: "Check-in",
                        projectPrefixId: projectPrefixId,
                        completedAt: null,
                        assigneePrefixId: checkin.assigneePrefixId,
                      });
                    }}
                  />
                </DropdownMenuSubContent>
              </DropdownMenuPortal>
            </DropdownMenuSub>
            <DropdownMenuSeparator />
            <DropdownMenuSub>
              <DropdownMenuSubTrigger>
                <UserPlus />
                Reassign
              </DropdownMenuSubTrigger>
              <DropdownMenuPortal>
                <DropdownMenuSubContent className="p-0">
                  {managers?.map((manager) => (
                    <DropdownMenuItem
                      key={manager.prefixId}
                      onClick={() => {
                        updateTask({
                          assigneePrefixId: manager.userPrefixId,
                        });
                      }}
                    >
                      <CraftworkerAvatar
                        name={manager.firstName}
                        avatarUrl={manager.avatarUrl ?? ""}
                        prefixId={manager.prefixId}
                      />
                      {`${manager.firstName} ${manager.lastName}`}
                    </DropdownMenuItem>
                  ))}
                </DropdownMenuSubContent>
              </DropdownMenuPortal>
            </DropdownMenuSub>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  );
};

export const CheckinListItemSkeleton = () => {
  return <div className="h-10 w-full animate-pulse rounded-lg bg-gray-100" />;
};
