import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="estimate"
export default class extends Controller {
  static targets = ["media"];

  connect() {
    // Check local storage for the media toggle state
    const mediaState = localStorage.getItem("mediaVisible");
    if (mediaState === "true") {
      this.showMedia();
    } else {
      this.hideMedia();
    }

    window.addEventListener("locationChange", () =>
      localStorage.removeItem("mediaVisible"),
    );
  }

  toggleMedia() {
    this.mediaTargets.forEach((media) => {
      media.classList.toggle("hidden");
    });
    // Save the current state to local storage
    const isVisible = !this.mediaTargets[0].classList.contains("hidden");
    localStorage.setItem("mediaVisible", isVisible);
  }

  showMedia() {
    this.mediaTargets.forEach((media) => {
      media.classList.remove("hidden");
    });
  }

  hideMedia() {
    this.mediaTargets.forEach((media) => {
      media.classList.add("hidden");
    });
  }
}
