import type { paths } from "@/types/api/v2/schema";

/**
 * Serialize parameters for API requests into URLSearchParams format.
 *
 * This function takes an object representing query parameters and converts it
 * into a URLSearchParams instance, which can be used in API requests.
 *
 * @param {Object} params - The parameters to serialize. This should be an object
 *                          where keys are parameter names and values are the
 *                          corresponding values. Nested objects and arrays are
 *                          supported.
 *
 * @returns {URLSearchParams} A URLSearchParams object containing the serialized
 *                            query parameters.
 *
 * @example
 * // Example usage:
 * const params = {
 *   search: 'test',
 *   filters: {
 *     category: 'books',
 *     tags: ['fiction', 'adventure']
 *   }
 * };
 * const queryString = serializeParams(params);
 * console.log(queryString.toString()); // Output: "search=test&filters[category]=books&filters[tags][]=fiction&filters[tags][]=adventure"
 */
const serializeParams = <Url extends keyof paths>(
  params?: paths[Url]["get"]["parameters"]["query"],
): URLSearchParams => {
  const queryParams = new URLSearchParams();

  const appendParams = (obj: Record<string, unknown>, prefix: string) => {
    Object.entries(obj).forEach(([key, value]) => {
      const paramKey = prefix ? `${prefix}[${key}]` : key;
      if (Array.isArray(value)) {
        value.forEach((item) => {
          queryParams.append(`${paramKey}[]`, item);
        });
      } else if (typeof value === "object" && value !== null) {
        appendParams(value as Record<string, unknown>, paramKey);
      } else if (value !== undefined && value !== null) {
        queryParams.set(paramKey, value.toString());
      }
    });
  };

  if (params) {
    appendParams(params, "");
  }

  return queryParams;
};

export default serializeParams;
