import { useApiQuery } from "@/api/openapi";

const useLocation = (locationId?: string) => {
  return useApiQuery(
    "/api/v2/locations/{id}",
    {
      id: locationId,
    },
    {
      enabled: Boolean(locationId),
    },
  );
};

export default useLocation;
