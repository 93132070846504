import type { UseQueryOptions } from "@tanstack/react-query";

import { useApiQuery } from "@/api/openapi";
import type { GetSuccessResponse } from "@/types/api/util";

const useProject = (
  projectId: string,
  options?: UseQueryOptions<GetSuccessResponse<"/api/v2/projects/{id}">>,
) => {
  return useApiQuery(
    "/api/v2/projects/{id}",
    {
      id: projectId,
    },
    options,
  );
};

export default useProject;
