import type { UseQueryOptions } from "@tanstack/react-query";

import { useApiQuery } from "@/api/openapi";
import type { GetParams, GetSuccessResponse } from "@/types/api/util";

const useEstimates = (
  params: GetParams<"/api/v2/estimates">,
  config: Omit<
    UseQueryOptions<GetSuccessResponse<"/api/v2/estimates">>,
    "queryKey" | "queryFn"
  >,
) => {
  return useApiQuery(
    "/api/v2/estimates",
    {
      params,
    },
    config,
  );
};

export default useEstimates;
